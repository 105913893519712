/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form } from 'antd';
import dayjs from 'dayjs';

import { Node } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Col } from '@/primitives/Col';
import { Row } from '@/primitives/Row';

import { IncomingData } from './IncomingData';
import { NodeViewPageCard } from './NodeViewPageCard';

export type NodeCredentialsProps = {
  node: Node | undefined;
  isPending: boolean;
};

export function NodeCredentials({ node, isPending }: NodeCredentialsProps) {
  const { t } = useMimicTranslation('node');

  return (
    <NodeViewPageCard title={t('credentials')}>
      <Form layout="vertical">
        <Row>
          <Col span={12}>
            <IncomingData
              label={t('last_cert_rotation')}
              loading={isPending}
              text={
                node?.credentialTimestamps?.lastRotation
                  ? dayjs(node?.credentialTimestamps?.lastRotation).utc().format('DD MMM YYYY HH:mm:ss')
                  : undefined
              }
            />
          </Col>
          <Col span={12}>
            <IncomingData
              label={t('cert_expiration')}
              loading={isPending}
              text={
                node?.credentialTimestamps?.notAfter
                  ? dayjs(node?.credentialTimestamps?.notAfter).utc().format('DD MMM YYYY HH:mm:ss')
                  : undefined
              }
            />
          </Col>
        </Row>
      </Form>
    </NodeViewPageCard>
  );
}
