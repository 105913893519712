/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Navigate, useSearchParams } from 'react-router-dom';

import { selectedTenantIDKey } from '@/state/mgmtPlaneStore';
import { LoadingSpinnerFullPage } from '@/v1/components/LoadingSpinnerFullPage';
import { LogIn } from '@/v1/components/LogIn';
import { useGetUserProfile } from '@/v1/utils/hooks/useGetUserProfile';

export function LogInRoute() {
  const { isFetching, isError, data } = useGetUserProfile();
  const [searchParams] = useSearchParams();
  const accountNotFound = searchParams.get('account-not-found');
  const unexpectedError = searchParams.get('unexpected-error');
  const emailMismatch = searchParams.get('email-mismatch');

  const triggerLogIn = (emailAddress: string) => {
    window.location.href = `${window.mimicConfig.MIMIC_MGMT_PLANE_API}/api/auth/sign-in?email=${emailAddress}`;
  };

  if (isFetching) return <LoadingSpinnerFullPage />;
  if (!isError && data) {
    const userProfile = data.data;
    let { tenantID } = userProfile;
    if (userProfile.role === 'superadmin') {
      tenantID = localStorage.getItem(selectedTenantIDKey) || tenantID;
    }
    return <Navigate to={`/tenants/${tenantID}/activity`} replace />;
  }

  return (
    <LogIn
      triggerLogIn={triggerLogIn}
      accountNotFound={accountNotFound}
      emailMismatch={emailMismatch}
      unexpectedError={!!unexpectedError}
    />
  );
}
