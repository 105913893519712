/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Flex as AntDFlex } from 'antd';

import { TShirtSize, tokens } from '@/theme';

export type FlexProps = {
  children?: React.ReactNode;
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
  vertical?: boolean;
  gap?: TShirtSize | 'none';
  wrap?: boolean;
  full?: boolean;
};

export function Flex({ justify, align, children, vertical, gap, wrap, full }: FlexProps) {
  const style: React.CSSProperties = {
    width: !vertical && full ? '100%' : undefined,
    height: vertical && full ? '100%' : undefined,
  };
  const gapSize = gap === 'none' ? 0 : tokens.size.space[gap || 'md'];
  const wrapAttr = wrap ? 'wrap' : undefined;
  return (
    <AntDFlex gap={gapSize} wrap={wrapAttr} justify={justify} align={align} vertical={vertical} style={style}>
      {children}
    </AntDFlex>
  );
}
