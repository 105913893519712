/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table/interface';

import { Activity, PaginatedActivitiesResponse } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Badge } from '@/primitives/Badge';
import { Container } from '@/primitives/Container';
import { Link } from '@/primitives/Link';
import { Result } from '@/primitives/Result';
import { badge, tokens } from '@/theme';
import { UpdateParams } from '@/utils/params';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { ConnectivityTime } from '@/v1/components/States/ConnectivityTime';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

export type ActivityListPageParams = {
  tenantID: string;
  activitiesQuery: UseQueryResult<PaginatedActivitiesResponse, Error>;
  onParamsChange: UpdateParams<Activity>;
};

export function ActivityListPage({ tenantID, activitiesQuery, onParamsChange }: ActivityListPageParams) {
  const { t } = useMimicTranslation('activity');
  const queryClient = useQueryClient();

  useSubscribeToEvents(
    ['hallmark:created', 'deflection:created', 'node:operational-state-changed', 'node:updated'],
    () => {
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  );

  const columns: ColumnsType<Activity> = [
    {
      title: t('table.hostName'),
      dataIndex: 'nodeName',
      key: 'nodeName',
      render: (nodeName: string, record: Activity) => (
        <Link
          to={`/tenants/${tenantID}/nodes/${record.nodeID}`}
          dataTestId="node-link"
          style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
        >
          {nodeName || t('notAvailable')}
        </Link>
      ),
    },
    {
      title: t('table.tags'),
      dataIndex: 'tags',
      key: 'tags',
    },
    {
      title: t('table.detection'),
      dataIndex: 'hallmarkCount',
      key: 'hallmarkCount',
      render: (count: number) => <Badge text={count} bgColor={badge.badgeYellowSolidBg} />,
    },
    {
      title: t('table.deflections'),
      dataIndex: 'deflectionsCount',
      key: 'deflectionsCount',
      render: (count) => {
        const bgColor = count > 0 ? badge.badgeRedSolidBg : badge.badgeDarkGraySubtleBg;
        const color = count > 0 ? tokens.color.text.textInverse : tokens.color.text.textDefault;
        const label = count > 0 ? t('table.deflectionsActive') : t('table.deflectionsInactive');
        return <Badge dataTestId="deflections-info" text={label} bgColor={bgColor} color={color} />;
      },
    },
    {
      title: t('table.connectivity'),
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      render: (_, record) => {
        return (
          <ConnectivityTime
            state={record.nodeConnectivity}
            lastSeen={record.lastSeen}
            heartbeatStates={record.nodeHeartbeatStates}
          />
        );
      },
    },
  ];

  const showEmpty =
    !activitiesQuery.isPending && !!activitiesQuery.data && activitiesQuery.data.meta.page.totalItems === 0;
  const showData = !showEmpty;

  return (
    <Container>
      {showEmpty && (
        <div style={{ backgroundColor: 'white', marginTop: '15px', padding: '100px', width: '100%' }}>
          <Result status="success" title={t('noWorries')} subTitle={t('allClear')} />
        </div>
      )}
      {showData && (
        <PaginatedTable
          paginatedResource={activitiesQuery}
          rowKey={(tag) => tag.nodeID}
          columns={columns}
          onParamsChange={onParamsChange}
        />
      )}
    </Container>
  );
}
