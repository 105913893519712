/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PaginationParams } from '@/client/mgmtPlane';
import { TypeMap, defineTypes, getFilters, setFilters } from '@/utils/filters';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, getPaginationParams, setPaginationParams } from '@/utils/pagination';
import { Sorter, UpdateParams } from '@/utils/params';
import { getSort, setSortParams } from '@/utils/sort';

const defaultTypeMap = defineTypes({});

export function useQueryParams<T, F = object, S = Array<string>>(
  types?: TypeMap<F>,
  scope?: string,
  defaultFilters?: F,
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastFilterApplied, setLastFilterApplied] = useState<string | undefined>(undefined);
  const typeMap = types || (defaultTypeMap as TypeMap<F>);
  const filters = { ...defaultFilters, ...getFilters(searchParams, typeMap, scope) };
  const sort = getSort<S>(searchParams, scope);
  const paginationParams = getPaginationParams(searchParams, scope);

  const updateFilters = (newFilters: Partial<F>) => {
    setFilters(searchParams, newFilters, scope);
    setSearchParams(searchParams);
  };

  const updatePagination = (params: PaginationParams) => {
    setPaginationParams(searchParams, params, scope);
    setSearchParams(searchParams);
  };

  const updateSort = (newSort: Sorter<T>[]) => {
    setSortParams(searchParams, newSort, scope);
    setSearchParams(searchParams);
  };

  const updateQueryParams: UpdateParams<T, F> = ({ filters: f, sort: s, paginationParams: p }) => {
    const requestedFiltersHash = JSON.stringify(f);
    if (f && requestedFiltersHash !== lastFilterApplied) {
      setLastFilterApplied(requestedFiltersHash);
      updateFilters(f);
    }
    if (p) {
      updatePagination(p);
    }
    if (s) {
      updateSort(s);
    }
  };

  if (paginationParams.number !== undefined && paginationParams.number < 1) {
    paginationParams.number = DEFAULT_PAGE_NUMBER;
    updatePagination(paginationParams);
  }
  if (paginationParams.size !== undefined && paginationParams.size < 0) {
    paginationParams.size = DEFAULT_PAGE_SIZE;
    updatePagination(paginationParams);
  }

  return {
    filters,
    sort,
    paginationParams,
    updateQueryParams,
  };
}
