/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Badge } from '@/primitives/Badge';
import { badge, tokens } from '@/theme';

import { IncomingData } from './IncomingData';

export type DetectionsInfoProps = {
  disabled: boolean;
  isLoading: boolean;
  hallmarksCount: number;
};

const detectionsBgColor = {
  componentDisabled: {
    active: badge.badgeYellowSubtleBg,
    inactive: badge.badgeLightGraySubtleBg,
  },
  componentEnabled: {
    active: badge.badgeYellowSolidBg,
    inactive: badge.badgeDarkGraySubtleBg,
  },
};

export function DetectionsInfo({ disabled, isLoading, hallmarksCount }: DetectionsInfoProps) {
  const { t } = useMimicTranslation('node');

  // label
  const detectionLabel = hallmarksCount || 0;

  // font color
  const detectionColor = disabled ? tokens.color.text.textDisabled : tokens.color.text.textSubtle;

  // background color
  const bgColors = disabled ? detectionsBgColor.componentDisabled : detectionsBgColor.componentEnabled;
  const bgColor = hallmarksCount > 0 ? bgColors.active : bgColors.inactive;

  return (
    <IncomingData
      disabled={disabled}
      marginBottom={0}
      label={t('detections')}
      loading={isLoading}
      bold
      labelMarginBottom="8px"
    >
      <Badge
        dataTestId="detections-info"
        text={detectionLabel}
        bgColor={bgColor}
        color={detectionColor}
        minWidth="44px"
        minHeight="36px"
        borderRadius="36px"
        size="xl"
      />
    </IncomingData>
  );
}
