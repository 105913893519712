/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import { ContentContainer } from '@/v1/components/ContentContainer';

export function SwaggerUiRoute() {
  return (
    <ContentContainer>
      <SwaggerUI
        url="/openapi.json"
        withCredentials
        tryItOutEnabled
        requestInterceptor={(req) => {
          return req;
        }}
      />
    </ContentContainer>
  );
}
