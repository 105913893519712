/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { PlusOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Modal } from 'antd';
import { useEffect, useState } from 'react';

import { ApiError, Tag, TagFilters } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Divider } from '@/primitives/Divider';
import { Dropdown } from '@/primitives/Dropdown';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { getValidationMessage, MimicApiError } from '@/utils/errors';
import { UpdateParams } from '@/utils/params';
import { validationErrors } from '@/v1/components/Forms/validationErrors';
import { GuardedButton } from '@/v1/components/GuardedButton';
import { PageHeader } from '@/v1/components/PageHeader';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { NewTagDialog, NewTagDialogProps } from '@/v1/components/Tags/NewTagDialog';
import { TagForm, TagFormFields } from '@/v1/components/Tags/TagForm';
import { GetTagListOptions } from '@/v1/utils/hooks/getTagListOptions';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

import { useTagListColumns } from './useTagListColumns';

export type TagsListPageParams = {
  tenantID: string | undefined;
  options: GetTagListOptions;
  onParamsChange: UpdateParams<Tag, Partial<TagFilters>>;
  onTagError: (tag: Tag, error: string) => void;
  onTagDeleted: (tag: Tag) => void;
  onTagCreated: (tag: Tag) => void;
  onTagUpdated: (oldTag: Tag, newTag: Tag) => void;
  onTagDelete: (tag: Tag) => Promise<Tag>;
  onTagCreate: NewTagDialogProps['onTagCreate'];
  onTagUpdate: (tag: Tag) => Promise<Tag>;
};

export function TagsListPage({
  tenantID,
  options,
  onParamsChange,
  onTagCreated,
  onTagDeleted,
  onTagUpdated,
  onTagError,
  onTagDelete,
  onTagCreate,
  onTagUpdate,
}: TagsListPageParams) {
  const { t } = useMimicTranslation('tags');
  const tagsQuery = useQuery(options);
  const queryClient = useQueryClient();
  const [deleteTag, setDeleteTag] = useState<Tag>();
  const [editTag, setEditTag] = useState<Tag>();
  const [newTagOpen, setNewTagOpen] = useState<boolean>();
  const [deleteIsPending, setDeleteIsPending] = useState(false);
  const [updateIsPending, setUpdateIsPending] = useState(false);
  const [editForm] = Form.useForm<TagFormFields>();

  useSubscribeToEvents(['tag:created', 'tag:updated', 'tag:deleted', 'node:tag-added', 'node:tag-deleted'], () => {
    queryClient.invalidateQueries({
      queryKey: ['tags', tenantID],
    });
  });

  useEffect(() => {
    if (editTag) editForm.resetFields();
  }, [editForm, editTag]);

  const closeDeleteDialog = () => {
    setDeleteTag(undefined);
    setDeleteIsPending(false);
  };

  const onCancelTagDelete = () => {
    closeDeleteDialog();
  };

  const onConfirmTagDelete = () => {
    if (deleteTag) {
      setDeleteIsPending(true);

      onTagDelete(deleteTag)
        .then(() => {
          onTagDeleted(deleteTag);
          closeDeleteDialog();
        })
        .catch((e: MimicApiError) => {
          if (e instanceof MimicApiError) {
            onTagError(deleteTag, e.body.message);
          }
          closeDeleteDialog();
        });
    }
  };

  const onAddedTag = (tag: Tag) => {
    setNewTagOpen(false);
    onTagCreated(tag);
  };

  const onNewTagOpenChange = () => {
    if (newTagOpen) {
      // Allows background clicks to close dropdown
      setNewTagOpen(undefined);
    }
  };

  const closeEditDialog = () => {
    setEditTag(undefined);
    setUpdateIsPending(false);
  };

  const onCancelEditTag = () => {
    closeEditDialog();
  };

  const onSaveEditTag = async () => {
    if (editTag) {
      try {
        await editForm.validateFields();
      } catch {
        return;
      }
      try {
        const name = editForm.getFieldValue('name');
        setUpdateIsPending(true);
        const oldTag = { ...editTag };
        const newTag = await onTagUpdate({ ...editTag, name });
        onTagUpdated(oldTag, newTag);
        closeEditDialog();
      } catch (e) {
        setUpdateIsPending(false);
        if (e instanceof ApiError) {
          const message = getValidationMessage(e, 'name');
          editForm.setFields(validationErrors<TagFormFields>({ name: message }));
        }
      }
    }
  };

  const newTagDialog = () => <NewTagDialog onTagCreate={onTagCreate} onAddedTag={onAddedTag} />;

  const searchText = tagsQuery.data?.meta.filters.name || '';
  const columns = useTagListColumns(searchText, setEditTag, setDeleteTag);

  return (
    <Container>
      <PageHeader title={t('title')} text={t('subtitle')} level={2}>
        <Container horizontal gap="sm">
          <Dropdown open={newTagOpen} onOpenChange={onNewTagOpenChange} customDialog={newTagDialog}>
            <GuardedButton
              requiredRole="editor"
              type="primary"
              dataTestId="new-tag"
              onClick={() => setNewTagOpen(!newTagOpen)}
            >
              <PlusOutlined /> {t('newTag')}
            </GuardedButton>
          </Dropdown>
        </Container>
      </PageHeader>

      <PaginatedTable
        paginatedResource={tagsQuery}
        rowKey={(tag) => tag.id}
        columns={columns}
        onParamsChange={onParamsChange}
      />

      <Modal
        open={Boolean(deleteTag)}
        onCancel={onCancelTagDelete}
        onOk={onConfirmTagDelete}
        cancelText={t('confirmDelete.cancel')}
        cancelButtonProps={{ disabled: deleteIsPending }}
        okText={t('confirmDelete.ok')}
        okButtonProps={{ danger: true, loading: deleteIsPending }}
      >
        <Container padding="sm">
          <Title level={4}>{t('confirmDelete.title', { name: deleteTag?.name })}</Title>
          <Text>{t('confirmDelete.body1', { useCount: deleteTag?.useCount })}</Text>
          <Text>{t('confirmDelete.body2')}</Text>
        </Container>
      </Modal>

      <Form.Provider onFormFinish={onSaveEditTag}>
        <Modal
          key={editTag?.id}
          open={Boolean(editTag)}
          onCancel={onCancelEditTag}
          onOk={onSaveEditTag}
          cancelText={t('editTag.cancelButton')}
          cancelButtonProps={{ disabled: updateIsPending }}
          okText={t('editTag.submitButton')}
          okButtonProps={{ loading: updateIsPending, htmlType: 'submit' }}
        >
          <Container gap="lg" full>
            <Title level={4} style={{ marginTop: '32px' }}>
              {t('editTag.title')}
            </Title>
            <TagForm tag={editTag} form={editForm} loading={updateIsPending} />
            <Divider />
          </Container>
        </Modal>
      </Form.Provider>
    </Container>
  );
}
