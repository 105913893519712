/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';

import {
  ConfigRevision,
  NodeConfig,
  NodeConfigFilters,
  NodeConfigSortableFields,
  PaginatedNodeConfigsResponse,
} from '@/client';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { defineTypes } from '@/utils/filters';
import { ContentContainer } from '@/v1/components/ContentContainer';
import { NodeConfigsList } from '@/v1/components/NodeConfigsList';
import { useNodeConfigsQuery } from '@/v1/utils/hooks/useNodeConfigsQuery';
import { useQueryParams } from '@/v1/utils/hooks/useQueryParams';

const NodeConfigsFilterTypes = defineTypes<NodeConfigFilters>({
  id: 'string',
  name: 'string',
  os: 'string',
});

export function ConfigsRoute() {
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const { filters, sort, paginationParams, updateQueryParams } = useQueryParams<
    NodeConfig,
    NodeConfigFilters,
    NodeConfigSortableFields
  >(NodeConfigsFilterTypes);

  const configsQuery = useNodeConfigsQuery({
    tenantId: selectedTenantID,
    filters,
    sort,
    settings: paginationParams,
  });
  const nodeConfigsQueryResponse = configsQuery as unknown as UseQueryResult<PaginatedNodeConfigsResponse, Error>;
  const nodeConfigPath = (nodeConfig: NodeConfig) => `/tenants/${selectedTenantID}/node-configs/${nodeConfig.id}`;
  const getNodeConfigurationRevisionPath = (nodeConfigRevision: ConfigRevision) =>
    `/tenants/${selectedTenantID}/node-configs/${nodeConfigRevision.nodeConfigId}/revisions/${nodeConfigRevision.revisionNumber}`;

  return (
    <ContentContainer width="100%" padding="xl">
      <NodeConfigsList
        tenantId={selectedTenantID}
        tags={[]}
        onFilterAndSortChange={updateQueryParams}
        nodeConfigsQueryResponse={nodeConfigsQueryResponse}
        getNodeConfigPath={nodeConfigPath}
        getNodeConfigurationRevisionPath={getNodeConfigurationRevisionPath}
      />
    </ContentContainer>
  );
}
