/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQueryClient, UseQueryResult } from '@tanstack/react-query';

import { Job, JobFilters, PaginatedJobsResponse } from '@/client';
import { Card } from '@/primitives/Card';
import { UpdateParams } from '@/utils/params';
import { JobListTable } from '@/v1/components/JobList/JobListTable';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

export type NodeConfigJobsHistoryProps = {
  nodeConfigID: string;
  tenantID: string;
  jobsQuery: UseQueryResult<PaginatedJobsResponse, Error>;
  jobFilters: JobFilters;
  updateQueryParams: UpdateParams<Job, Partial<JobFilters>>;
};

export function NodeConfigJobsHistory({
  nodeConfigID,
  tenantID,
  jobsQuery,
  jobFilters,
  updateQueryParams,
}: NodeConfigJobsHistoryProps) {
  const queryClient = useQueryClient();

  useSubscribeToEvents(
    ['node:operational-state-changed', 'node-config:assigned', 'job:updated', 'job:completed', 'job:timedout'],
    (event) => {
      if (!event.entityIds.includes(nodeConfigID)) return;
      queryClient.invalidateQueries({ queryKey: ['jobs', tenantID] });
    },
  );

  return (
    <Card padding="md" gap="sm">
      <JobListTable
        tenantID={tenantID}
        jobsQuery={jobsQuery}
        updateQueryParams={updateQueryParams}
        jobFilters={jobFilters}
      />
    </Card>
  );
}
