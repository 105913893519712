/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ReactNode } from 'react';

import { StatusColorType, tokens } from '@/theme';

export type TextBadgeProps = {
  status?: StatusColorType;
  children: ReactNode;
};
export function TextBadge({ children, status }: TextBadgeProps) {
  const color = tokens.color.components.badge.semantic.subtle[status || 'default'];
  return (
    <span
      style={{
        display: 'inline-block',
        borderRadius: '50px',
        color: color.color,
        backgroundColor: color.backgroundColor,
        paddingLeft: tokens.size.size200,
        paddingRight: tokens.size.size200,
        fontSize: tokens.size.font.md,
      }}
    >
      {children}
    </span>
  );
}
