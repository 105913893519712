/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';

import { Tooltip } from '@/primitives/Tooltip';
import { timeAgo } from '@/utils/time';

dayjs.extend(utc);

export type RelativeTimeProps = {
  date: Date | string | number;
  cutOffHrs?: number;
};

function ensureDate(date: Date | string | number): Date {
  switch (typeof date) {
    case 'string':
    case 'number':
      return new Date(date);
    default:
      return date;
  }
}

function hoursAgo(date: Date) {
  const diff = Number(new Date()) - Number(date);
  const minute = 60 * 1000;
  const hour = minute * 60;
  return Math.round(diff / hour);
}

function formatDate(date: Date, utcBool?: boolean): string {
  if (utcBool) {
    return dayjs(date).utc().format('D MMM YYYY HH:mm:ss');
  }
  return dayjs(date).format('MM/D/YYYY HH:mm:ss');
}

function getRelTime(date: Date, cuttoff: number) {
  const relative = hoursAgo(date) < cuttoff;
  return {
    date,
    showRelative: relative,
    relative: timeAgo(date),
    absolute: formatDate(date),
    absoluteUTC: formatDate(date, true),
  };
}

export function RelativeTime({ date, cutOffHrs }: RelativeTimeProps) {
  const cuttoff = cutOffHrs || 24;
  const dateObj = ensureDate(date);
  const relTime = getRelTime(dateObj, cuttoff);

  if (relTime.showRelative) {
    return <RelativeTimeOutput initialRelTime={relTime} cuttoff={cuttoff} />;
  }
  return relTime.absoluteUTC;
}

function RelativeTimeOutput({
  initialRelTime,
  cuttoff,
}: {
  initialRelTime: ReturnType<typeof getRelTime>;
  cuttoff: number;
}) {
  const [relTime, setRelTime] = useState(initialRelTime);

  useEffect(() => {
    setTimeout(() => setRelTime(getRelTime(initialRelTime.date, cuttoff)));
    const timer = setInterval(() => {
      setRelTime(getRelTime(initialRelTime.date, cuttoff));
    }, 1000);
    return () => clearInterval(timer);
  }, [setRelTime, initialRelTime.date, cuttoff]);

  return <Tooltip title={relTime.absolute}>{relTime.relative}</Tooltip>;
}
