/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Node, NodeResponse, PatchNode } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type UpdateNodeMutationConfig = {
  onSuccess?: (data: NodeResponse, variables: PatchNode) => void;
  onError?: (error: unknown, variables: PatchNode) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

export function useUpdateNodeMutation(tenantId: string, node: Node, config?: UpdateNodeMutationConfig) {
  const queryClient = useQueryClient();

  return useMutation<NodeResponse, unknown, PatchNode>({
    mutationFn: (patchNodeDto: PatchNode) => {
      return mgmtPlaneAPIClient.nodes.updateNode({ tenantId, id: node.id, requestBody: patchNodeDto });
    },
    onSuccess: (data, variables) => {
      if (config?.onSuccess) {
        config.onSuccess(data, variables);
      }
      queryClient.invalidateQueries({ queryKey: ['node', tenantId, node.id] });
      queryClient.invalidateQueries({ queryKey: ['nodes'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  });
}
