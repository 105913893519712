/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { UserResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export function useGetUserProfile(retry = false) {
  const { isError, data, error, isFetching, refetch } = useQuery({
    queryKey: ['userProfile'],
    queryFn: async function getUserProfile(): Promise<UserResponse> {
      const profileResponse = await mgmtPlaneAPIClient.users.getUserProfile();

      Sentry.setUser({
        id: profileResponse.data.id,
        name: profileResponse.data.displayName,
        email: profileResponse.data.email,
      });

      return profileResponse;
    },
    retry,
  });

  return { isError, data, error, isFetching, refetch };
}
