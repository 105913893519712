/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';

import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export function useGetJobNotifications(tenantId?: string) {
  return useQuery({
    queryKey: ['job-notifications', tenantId],
    queryFn: async () => {
      if (!tenantId) return null;
      return mgmtPlaneAPIClient.jobs.getJobs({
        filters: {
          notificationDismissed: false,
          status: ['error', 'warning', 'timeout', 'running'].join(','),
        },
        page: 1,
        pageSize: 5,
        sort: ['-jobNumber'],
        tenantId,
      });
    },
    enabled: !!tenantId,
  });
}
