/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { LoadingSpinnerFullPage } from '@/v1/components/LoadingSpinnerFullPage';
import { useGetUserProfile } from '@/v1/utils/hooks/useGetUserProfile';

type AuthenticatedRouteProps = {
  children: React.ReactNode;
};

export function AuthenticatedRoute({ children }: AuthenticatedRouteProps) {
  const setProfile = useMgmtPlaneStateStore((state) => state.setProfile);
  const { isFetching, isError, data, refetch } = useGetUserProfile();
  const location = useLocation();

  useEffect(() => {
    refetch();
  }, [refetch, location.pathname, location.search, location.hash]);

  useEffect(() => {
    if (isFetching) return;
    setProfile(data?.data);
  }, [data, isFetching, setProfile]);

  if ((!isFetching && !data) || isError) return <Navigate to="/login" replace />;
  if (isFetching && !data) return <LoadingSpinnerFullPage />;

  return children;
}
