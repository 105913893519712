/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';

import { ApiError, JobResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type GetJobQueryParams = {
  tenantId?: string;
  jobId?: string;
};

export function useGetJobQuery({ tenantId, jobId }: GetJobQueryParams) {
  return useQuery<JobResponse, ApiError>({
    queryKey: ['jobs', tenantId, jobId],
    queryFn: async () => {
      return mgmtPlaneAPIClient.jobs.getJob({
        tenantId: tenantId!,
        jobId: jobId!,
      });
    },
    enabled: !!tenantId && !!jobId,
  });
}
