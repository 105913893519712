/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { EditOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { FocusEventHandler, useEffect, useState } from 'react';

import { Input } from '@/primitives/Input';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

export type EditableNodeNameProps = {
  name: string;
  onNameChange: (newNodeName: string) => void;
};

export function EditableNodeName({ name, onNameChange }: EditableNodeNameProps) {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [nodeName, setNodeName] = useState(name);

  useEffect(() => {
    if (isEditing) return;
    form.setFieldValue('name', name);
    setNodeName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, name]);

  const onNameBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const newNodeName = event.target.value.trim();
    setIsEditing(false);
    onNameChange(newNodeName);
    setNodeName(newNodeName);
  };

  return (
    <>
      {!isEditing && (
        <Space>
          <Text data-testid="node-name" size="xl">
            {nodeName}
          </Text>
          <EditOutlined
            onClick={() => setIsEditing(true)}
            data-testid="edit-node-name"
            style={{
              fontSize: '1.25rem',
              color: tokens.color.components.link.linkDefault,
              display: 'flex',
              justifyItems: 'center',
              alignItems: 'center',
              height: '44px',
              width: '44px',
            }}
          />
        </Space>
      )}
      {isEditing && (
        <Form form={form} initialValues={{ name }} layout="vertical" style={{ width: '100%' }}>
          <Form.Item name="name">
            <Input
              allowClear
              count={{ max: 80 }}
              maxLength={80}
              dataTestId="node-name-input"
              onBlur={(event) => onNameBlur(event)}
              autoFocus
            />
          </Form.Item>
        </Form>
      )}
    </>
  );
}
