/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';

import { ConfigRevisionFilters, ConfigRevisionsSortableFields } from '@/client';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';

export type ConfigRevisionsQueryParams = {
  tenantId?: string;
  configId?: string;
  filters?: ConfigRevisionFilters;
  sort?: ConfigRevisionsSortableFields;
  settings: PaginationParams;
};

export function useConfigRevisionsQuery({ tenantId, configId, filters, sort, settings }: ConfigRevisionsQueryParams) {
  return useQuery({
    queryKey: ['config-revisions', tenantId, configId, settings.number, settings.size, sort, filters],
    queryFn: async () => {
      return mgmtPlaneAPIClient.nodeConfigs.getConfigRevisions({
        tenantId: tenantId!,
        configId: configId!,
        page: settings.number,
        pageSize: settings.size,
        filters,
        sort,
      });
    },
    staleTime: 5 * 1000,
    enabled: !!tenantId && !!configId,
  });
}
