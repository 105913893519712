/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'node_modules/@tanstack/react-query/build/legacy';

import { ApiError, NodeTagResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type NewRemoveTagMutationConfig = {
  onSuccess?: (data: NodeTagResponse, params: RemoveNodeTag) => void;
  onError?: (error: ApiError, params: RemoveNodeTag) => void;
  onSettled?: (data: NodeTagResponse | undefined, error: ApiError | null, params: RemoveNodeTag) => void;
};

export type RemoveNodeTag = {
  nodeId: string;
  tagName: string;
};

export function getRemoveTagMutationOptions(
  tenantId: string,
  queryClient: QueryClient,
  config?: NewRemoveTagMutationConfig,
) {
  return {
    mutationFn: (params: RemoveNodeTag) => {
      return mgmtPlaneAPIClient.nodes.removeNodeTag({ tenantId, id: params.nodeId, tagName: params.tagName });
    },
    onSuccess: async (data, params) => {
      if (config?.onSuccess) {
        await config.onSuccess(data, params);
      }
      queryClient.invalidateQueries({ queryKey: ['node', tenantId, params.nodeId] });
      queryClient.invalidateQueries({ queryKey: ['nodes'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  } satisfies UseMutationOptions<NodeTagResponse, ApiError, RemoveNodeTag>;
}

export function useRemoveTagMutation(tenantID: string, config?: NewRemoveTagMutationConfig) {
  const queryClient = useQueryClient();
  return useMutation<NodeTagResponse, ApiError, RemoveNodeTag>(
    getRemoveTagMutationOptions(tenantID, queryClient, config),
  );
}
