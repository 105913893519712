/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Button } from '@/primitives/Button';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

export type MimicSyntaxHighlighterProps = {
  source: string | undefined;
  language?: 'go' | 'json';
};

export function MimicSyntaxHighlighter({ source, language = 'go' }: MimicSyntaxHighlighterProps) {
  const { t } = useMimicTranslation('mimicSyntaxHighlighter');
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(source!);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '8px',
          backgroundColor: tokens.color.surface.surfaceLow,
          border: `1px solid ${tokens.color.border.borderDefault}`,
          padding: '8px',
        }}
      >
        {copied ? (
          <Button
            type="default"
            style={{ height: '28px', display: 'flex', alignItems: 'center', borderRadius: '2px' }}
            onClick={onCopy}
          >
            <CheckOutlined style={{ fontSize: '12px', color: tokens.color.success.successDefault }} />
            <Text type="default" size="sm">
              {t('copied')}
            </Text>
          </Button>
        ) : (
          <Button
            type="default"
            style={{ height: '28px', display: 'flex', alignItems: 'center', borderRadius: '2px' }}
            onClick={onCopy}
          >
            <CopyOutlined style={{ fontSize: '12px' }} />
            <Text type="default" size="sm">
              {t('copy')}
            </Text>
          </Button>
        )}
      </div>
      <div style={{ flex: 1, overflowY: 'auto', fontFamily: 'DM Mono' }}>
        <SyntaxHighlighter
          language={language}
          style={docco}
          showLineNumbers
          lineNumberStyle={{
            paddingLeft: '5px',
            paddingRight: '5px',
            marginRight: '15px',
            height: '26px',
            color: '#191919',
            fontSize: '1rem',
            backgroundColor: 'rgb(25, 25, 25, 0.04)',
            fontFamily: 'DM Mono',
            width: '50px',
          }}
          customStyle={{
            backgroundColor: 'white',
            border: '1px solid rgb(25, 25, 25, 0.1)',
            fontSize: '14px',
            padding: '0',
            margin: '0',
          }}
        >
          {ensureThirtyLines(source)}
        </SyntaxHighlighter>
      </div>
    </>
  );
}

function ensureThirtyLines(source = '') {
  const lines = source.split('\n');
  const lineCount = lines.length;

  if (lineCount < 15) {
    const additionalLines = 15 - lineCount;
    for (let i = 0; i < additionalLines; i += 1) {
      lines.push('');
    }
  }

  return lines.join('\n');
}
