/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Card as AntDCard } from 'antd';
import React from 'react';

import { TShirtSize, tokens } from '@/theme';
import './NodeViewCard.css';

export type NodeViewCardProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
  style?: React.CSSProperties;
  bordered?: boolean;
  gap?: TShirtSize;
  dataTestId?: string;
  bodyStyles?: React.CSSProperties;
};

export function NodeViewCard({ title, style, children, bordered, gap, dataTestId, bodyStyles }: NodeViewCardProps) {
  const padding = tokens.size.card[gap || 'xxl'];
  const margin = 0;
  const styleOverrides = style || {};
  return (
    <AntDCard
      title={title}
      styles={{
        header: { borderBottom: 0, marginBottom: 0 },
        body: { paddingTop: 0, ...bodyStyles },
      }}
      style={{ padding, margin, ...styleOverrides }}
      bordered={bordered}
      data-testid={dataTestId}
    >
      {children}
    </AntDCard>
  );
}
