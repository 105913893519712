/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { QuestionCircleOutlined } from '@ant-design/icons';

import { Text } from '@/primitives/Text';

import AppleSvg from './OsIcon/Apple.svg?react';
import DebianSvg from './OsIcon/Debian.svg?react';
import LinuxSvg from './OsIcon/Linux.svg?react';
import RedhatSvg from './OsIcon/Redhat.svg?react';
import WindowsSvg from './OsIcon/Windows.svg?react';

export type OsIconProps = {
  name: 'windows' | 'linux' | 'debian' | 'redhat' | 'mac' | 'not_applicable';
  color?: string;
};

function getIconForOs(name: OsIconProps['name']) {
  switch (name) {
    case 'mac': {
      return AppleSvg;
    }
    case 'windows': {
      return WindowsSvg;
    }
    case 'linux': {
      return LinuxSvg;
    }
    case 'debian': {
      return DebianSvg;
    }
    case 'redhat': {
      return RedhatSvg;
    }
    default:
      return QuestionCircleOutlined;
  }
}

export function OsIcon({ name, color }: OsIconProps) {
  const Icon = getIconForOs(name);

  if (name === 'not_applicable' || !name) {
    return <Text type="default">-</Text>;
  }

  return <Icon style={{ color }} data-testid={name} className="anticon ant-dropdown-menu-item-icon" />;
}
