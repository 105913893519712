/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { en } from '@/translation/en';
import { pt } from '@/translation/pt';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: true,
    resources: {
      'pt-BR': pt,
      'en-US': en,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

function changeLanguage(lng: 'en-US' | 'pt-BR') {
  i18n.changeLanguage(lng);
}

export { changeLanguage, i18n };
