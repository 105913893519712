/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Tabs as AntDTabs, TabsProps as AntDTabsProps } from 'antd';
import { CSSProperties } from 'react';

export type TabsProps = {
  className?: string;
  style?: CSSProperties;
  onChange?: AntDTabsProps['onChange'];
  items: { key: string; label: string; children: React.ReactNode }[];
};

export function Tabs({ items, onChange, className, style }: TabsProps) {
  return <AntDTabs className={className} style={style} items={items} onChange={onChange} destroyInactiveTabPane />;
}
