/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Badge } from '@/primitives/Badge';
import { badge, tokens } from '@/theme';

import { IncomingData } from './IncomingData';

export type DeflectionsInfoProps = {
  disabled: boolean;
  isLoading: boolean;
  deflectionsCount: number;
};

const detectionsBgColor = {
  componentDisabled: {
    bgColor: {
      active: badge.badgeRedSubtleBg,
      inactive: badge.badgeLightGraySubtleBg,
    },
    color: {
      active: tokens.color.text.textInverse,
      inactive: tokens.color.text.textDisabled,
    },
  },
  componentEnabled: {
    bgColor: {
      active: badge.badgeRedSolidBg,
      inactive: badge.badgeDarkGraySubtleBg,
    },
    color: {
      active: tokens.color.text.textInverse,
      inactive: tokens.color.text.textDefault,
    },
  },
};

export function DeflectionsInfo({ disabled, isLoading, deflectionsCount }: DeflectionsInfoProps) {
  const { t } = useMimicTranslation('node');

  // label
  const deflectionsLabel = deflectionsCount > 0 ? t('deflectionsActive') : t('deflectionsInactive');

  // background color
  const bgColors = disabled ? detectionsBgColor.componentDisabled.bgColor : detectionsBgColor.componentEnabled.bgColor;
  const deflectionsBgColor = deflectionsCount > 0 ? bgColors.active : bgColors.inactive;

  // font color
  const colors = disabled ? detectionsBgColor.componentDisabled.color : detectionsBgColor.componentEnabled.color;
  const deflectionsColor = deflectionsCount > 0 ? tokens.color.text.textSubtle : colors.inactive;

  return (
    <IncomingData
      disabled={disabled}
      marginBottom={0}
      label={t('deflections')}
      loading={isLoading}
      bold
      labelMarginBottom="8px"
    >
      <Badge
        dataTestId="deflections-info"
        text={deflectionsLabel}
        bgColor={deflectionsBgColor}
        color={deflectionsColor}
        minHeight="36px"
        borderRadius="36px"
        size="xl"
      />
    </IncomingData>
  );
}
