/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Layout as AntDLayout } from 'antd';
import { CSSProperties } from 'react';

import { tokens } from '@/theme';

const { Sider: AntDSider } = AntDLayout;

const style: CSSProperties = {
  borderRight: `1px solid ${tokens.color.border.borderDefault}`,
  overflow: 'auto',
  height: '100%',
};

const { width } = tokens.size.menu;

export type SiderProps = {
  children: React.ReactNode;
};

export function Sider({ children }: SiderProps) {
  return (
    <AntDSider width={width} style={style}>
      {children}
    </AntDSider>
  );
}
