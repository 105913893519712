/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Avatar } from '@/primitives/Avatar';
import { Button } from '@/primitives/Button';
import { Flex } from '@/primitives/Flex';
import Tail from '@/primitives/Icons/Tail.svg?react';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

import { ConfigureNodesJobWizardSteps } from './ConfigureNodesJobWizardSteps';

import './ConfigureNodesJobWizardHeader.css';

export type ConfigureNodesJobWizardHeaderProps = {
  onClick: () => void;
  currentStep: ConfigureNodesJobWizardSteps;
};

export function ConfigureNodesJobWizardHeader({ onClick, currentStep }: ConfigureNodesJobWizardHeaderProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');

  return (
    <div className="job-wizard-status">
      <div className="job-wizard-status-step-container">
        <Flex align="center" gap="sm">
          {currentStep === 'select-config' || currentStep === 'review-job' ? (
            <CheckCircleFilled style={{ fontSize: '2rem' }} />
          ) : (
            <Avatar
              size="sm"
              text="1"
              backgroundColor={currentStep === 'select-nodes' ? tokens.color.components.steps.stepCurrentBg : ''}
              color={currentStep === 'select-nodes' ? tokens.color.text.textDefault : tokens.color.text.textSubtle}
            />
          )}
          <Text
            type={currentStep === 'select-nodes' ? 'default' : 'subtle'}
            size="md"
            strong={currentStep === 'select-nodes'}
          >
            {t('selectNodes')}
          </Text>
        </Flex>
        <Tail width="32px" />
        <Flex align="center" gap="sm">
          {currentStep === 'review-job' ? (
            <CheckCircleFilled style={{ fontSize: '2rem' }} />
          ) : (
            <Avatar
              size="sm"
              text="2"
              backgroundColor={currentStep === 'select-config' ? tokens.color.components.steps.stepCurrentBg : ''}
              color={currentStep === 'select-config' ? tokens.color.text.textDefault : tokens.color.text.textSubtle}
            />
          )}

          <Text
            type={currentStep === 'select-config' ? 'default' : 'subtle'}
            size="md"
            className="select-config"
            strong={currentStep === 'select-config'}
          >
            {t('selectConfig')}
          </Text>
        </Flex>
        <Tail width="32px" />
        <Flex align="center" gap="sm">
          <Avatar
            size="sm"
            text="3"
            backgroundColor={currentStep === 'review-job' ? tokens.color.components.steps.stepCurrentBg : ''}
            color={currentStep === 'review-job' ? tokens.color.text.textDefault : tokens.color.text.textSubtle}
          />
          <Text
            type={currentStep === 'review-job' ? 'default' : 'subtle'}
            size="md"
            className="review-job"
            strong={currentStep === 'review-job'}
          >
            {t('reviewJob')}
          </Text>
        </Flex>
      </div>
      <Button dataTestId="close-job-wizard" type="text" onClick={onClick}>
        <CloseOutlined />
      </Button>
    </div>
  );
}
