/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';

import { ApiError, NodeConfigResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export function useGetNodeConfigQuery(tenantId: string, configId: string) {
  return useQuery<NodeConfigResponse, ApiError>({
    queryKey: ['node-config', configId],
    queryFn: async () => {
      return mgmtPlaneAPIClient.nodeConfigs.getConfig({ tenantId: tenantId!, configId: configId! });
    },
    enabled: !!tenantId && !!configId,
  });
}
