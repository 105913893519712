/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Tag, TagFilters, TagSortableFields } from '@/client';
import { useMessage } from '@/hooks/message';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { defineTypes } from '@/utils/filters';
import { ContentContainer } from '@/v1/components/ContentContainer';
import { getTagListOptions } from '@/v1/utils/hooks/getTagListOptions';
import { useDeleteTagMutation } from '@/v1/utils/hooks/useDeleteTagMutation';
import { useNewTagMutation } from '@/v1/utils/hooks/useNewTagMutation';
import { useQueryParams } from '@/v1/utils/hooks/useQueryParams';
import { useUpdateTagMutation } from '@/v1/utils/hooks/useUpdateTagMutation';

import { TagsListPage } from './TagsListPage';

const TagFitlerTypes = defineTypes<TagFilters>({
  name: 'string',
});

export function TagsRoute() {
  const { t } = useMimicTranslation('tags');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const { mutateAsync: newTagMutate } = useNewTagMutation(tenantID!);
  const { mutateAsync: deleteTagMutate } = useDeleteTagMutation(tenantID!);
  const { mutateAsync: updateTagMutate } = useUpdateTagMutation(tenantID!);
  const [message] = useMessage();
  const { filters, sort, paginationParams, updateQueryParams } = useQueryParams<Tag, TagFilters, TagSortableFields>(
    TagFitlerTypes,
  );

  const options = getTagListOptions(tenantID!, filters, sort, paginationParams);

  const onTagDeleted = async (tag: Tag) => {
    message.success(t('tagDeleted', tag));
  };

  const onTagError = async (_tag: Tag, error: string) => {
    message.error(error);
  };

  const onTagCreated = async (tag: Tag) => {
    message.success(t('newTagCreated', tag));
  };

  const onTagCreate = async (name: string) => {
    const { data: tag } = await newTagMutate({ name });
    return tag;
  };

  const onTagDelete = async (tag: Tag) => {
    await deleteTagMutate({ id: tag.id });
    return tag;
  };

  const onTagUpdate = async (tag: Tag) => {
    const { data: updated } = await updateTagMutate(tag);
    return updated;
  };

  const onTagUpdated = async (oldTag: Tag, newTag: Tag) => {
    message.success(t('tagUpdated', { newName: newTag.name, oldName: oldTag.name }));
  };

  return (
    <ContentContainer>
      <TagsListPage
        tenantID={tenantID}
        options={options}
        onParamsChange={updateQueryParams}
        onTagCreated={onTagCreated}
        onTagDeleted={onTagDeleted}
        onTagUpdated={onTagUpdated}
        onTagDelete={onTagDelete}
        onTagCreate={onTagCreate}
        onTagUpdate={onTagUpdate}
        onTagError={onTagError}
      />
    </ContentContainer>
  );
}
