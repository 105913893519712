/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import type {} from '@redux-devtools/extension'; // required for devtools typing

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSetupCLISlice, SetupCLISlice } from './setupCLISlice';
import { createToastMessagesSlice, ToastMessagesSlice } from './toastMessagesSlice';
import { createUserProfileSlice, UserProfileSlice } from './userProfileSlice';
import { createWebsocketSlice, WebsocketSlice } from './websocketSlice';

export type MgmtPlaneState = ToastMessagesSlice & UserProfileSlice & SetupCLISlice & WebsocketSlice;

export const useMgmtPlaneStateStore = create<MgmtPlaneState>()(
  devtools((...a) => ({
    ...createUserProfileSlice(...a),
    ...createSetupCLISlice(...a),
    ...createToastMessagesSlice(...a),
    ...createWebsocketSlice(...a),
  })),
);

export * from './setupCLISlice';
export * from './toastMessagesSlice';
export * from './userProfileSlice';
export * from './websocketSlice';
