import { useEffect } from 'react';

import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function useTenantChannel(tenantID?: string) {
  const socket = useMgmtPlaneStateStore((state) => state.socket);
  const setChannel = useMgmtPlaneStateStore((state) => state.setChannel);

  useEffect(() => {
    if (!tenantID) return undefined;
    if (!socket) return undefined;

    const tenantTopicID = `tenant:${tenantID}`;

    const newTenantChannel = socket.channel(tenantTopicID);

    newTenantChannel?.join().receive('error', (err) => {
      // eslint-disable-next-line no-console
      console.warn('Websocket join error', err);
    });

    newTenantChannel.onError((err) => {
      // eslint-disable-next-line no-console
      console.warn('Websocket channel error', err);
    });

    setChannel(newTenantChannel);

    return () => {
      if (!newTenantChannel) return;
      newTenantChannel.leave();
    };
  }, [tenantID, socket, setChannel]);
}
