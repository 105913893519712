/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { $Dictionary } from 'node_modules/i18next/typescript/helpers';
import { useTranslation } from 'react-i18next';

import { en } from '@/translation/en';

type DotNotationKeys<T, Prefix extends string = ''> = T extends object
  ? {
      [K in keyof T]-?: K extends string ? `${Prefix}${K}` | DotNotationKeys<T[K], `${Prefix}${K}.`> : never;
    }[keyof T]
  : '';

type TranslationObject = typeof en.translation;

export function useMimicTranslation<K extends keyof TranslationObject>(
  keyPrefix: K,
): { t: (key: DotNotationKeys<TranslationObject[K]>, options?: $Dictionary & { defaultValue?: string }) => string } {
  const { t } = useTranslation(undefined, { keyPrefix });
  return { t };
}

export type MimicTranslationFunction<K extends keyof TranslationObject> = ReturnType<
  typeof useMimicTranslation<K>
>['t'];
