/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CloseCircleFilled } from '@ant-design/icons';

import { tokens } from '@/theme';

export type FailureIconParams = {
  on?: 'toast' | 'form';
};

const COLOR_MAP = {
  default: tokens.color.failure.failureIcon,
  toast: tokens.color.failure.failureOnToast,
  form: tokens.color.error.error,
};

export function FailureIcon({ on }: FailureIconParams) {
  const color = COLOR_MAP[on || 'default'];
  return <CloseCircleFilled style={{ color }} />;
}
