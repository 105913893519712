/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';

import { Node, NodeFilters, PaginatedNodesResponse, Tag } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { TableProps } from '@/primitives/Table';
import { UpdateParams } from '@/utils/params';
import { PaginatedTable } from '@/v1/components/PaginatedTable';

import { ExpandedRow } from './ExpandedRow';
import { NodesColumns, getNodesColumns } from './getNodesColumns';

export type NodesTableProps = {
  tenantID: string;
  nodesQuery: UseQueryResult<PaginatedNodesResponse>;
  onParamsChange: UpdateParams<Node, Partial<NodeFilters>>;
  columns: NodesColumns[];
  tags?: Tag[];
  getNodeConfigurationPath: (nodeConfigId: string) => string;
  onAssignConfiguration?: (node: Node) => void;
  rowSelection?: TableProps<Node>['rowSelection'];
  selectedNodeIds?: string[];
  primaryAction?: React.ReactNode;
  selectionWarning?: string;
  selectionError?: string;
  hideFilters?: boolean;
};

const expandedRowRender = (record: Node) => <ExpandedRow node={record} />;

export function NodesTable({
  nodesQuery,
  onParamsChange,
  columns,
  tags,
  tenantID,
  getNodeConfigurationPath,
  onAssignConfiguration,
  rowSelection,
  selectedNodeIds,
  primaryAction,
  selectionWarning,
  selectionError,
  hideFilters,
}: NodesTableProps) {
  const { t } = useMimicTranslation('nodes');

  const filters = nodesQuery.data?.meta.filters;
  const columnsShown = getNodesColumns(
    columns,
    tenantID,
    t,
    getNodeConfigurationPath,
    tags,
    filters,
    onAssignConfiguration,
    hideFilters,
  );

  return (
    <PaginatedTable
      rowKey="id"
      data-testid="node-table"
      columns={columnsShown}
      paginatedResource={nodesQuery}
      onParamsChange={onParamsChange}
      rowSelection={rowSelection}
      primaryAction={primaryAction}
      expandable={{
        expandedRowRender,
        rowExpandable: () => true,
      }}
      selectedIds={selectedNodeIds}
      selectionWarning={selectionWarning}
      selectionError={selectionError}
    />
  );
}
