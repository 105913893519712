/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Job, JobFilters, JobSortableFields, Node, NodeConfig, NodeFilters, NodeSortableFields } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Flex } from '@/primitives/Flex';
import { Tabs } from '@/primitives/Tabs';
import { defineTypes } from '@/utils/filters';
import { BackButton } from '@/v1/components/Buttons/BackButton';
import { NodeConfigJobsHistory } from '@/v1/components/NodeConfigurationDetails/NodeConfigJobsHistory';
import { getTagListOptions } from '@/v1/utils/hooks/getTagListOptions';
import { useGetJobsQuery } from '@/v1/utils/hooks/useGetJobsQuery';
import { NodesFilterTypes, useNodesQuery } from '@/v1/utils/hooks/useNodesQuery';
import { useQueryParams } from '@/v1/utils/hooks/useQueryParams';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

import { ConfigurationDetailsRevisions } from './ConfigurationDetailsRevisions';
import { NodeConfigNodes } from './NodeConfigNodes';
import { NodeConfigurationDetailsHeader } from './NodeConfigurationDetailsHeader';

import './NodeConfigurationDetails.css';

export type NodeConfigurationDetailsProps = {
  tenantID: string;
  nodeConfig: NodeConfig;
};

export function NodeConfigurationDetails({ tenantID, nodeConfig }: NodeConfigurationDetailsProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');
  const queryClient = useQueryClient();

  useSubscribeToEvents(['node-config:updated'], (event) => {
    if (!event.entityIds.includes(nodeConfig.id)) return;

    queryClient.invalidateQueries({
      queryKey: ['node-config', nodeConfig.id],
    });
  });

  const {
    filters: nodesFilters,
    sort: nodesSort,
    paginationParams: nodesPaginationParams,
    updateQueryParams: nodesUpdateQueryParams,
  } = useQueryParams<Node, NodeFilters, NodeSortableFields>(NodesFilterTypes, undefined, {
    appliedNodeConfigId: nodeConfig.id,
  });

  const nodesQuery = useNodesQuery(tenantID, nodesFilters, nodesSort, nodesPaginationParams);

  const tagOptions = getTagListOptions(tenantID!, nodesFilters, ['+name'], nodesPaginationParams);
  const { data: tagsList } = useQuery(tagOptions);

  const JobFilterTypes = defineTypes<JobFilters>({
    name: 'string',
    targetConfig: 'string',
    operatingSystem: 'string',
    notificationDismissed: 'boolean',
    nodeConfigId: 'string',
    status: 'string',
  });

  const {
    filters: jobsFilters,
    sort,
    paginationParams,
    updateQueryParams: jobsUpdateQueryParams,
  } = useQueryParams<Job, JobFilters, JobSortableFields>(JobFilterTypes, 'jobs');

  const jobsQuery = useGetJobsQuery({
    tenantId: tenantID!,
    filters: jobsFilters,
    sort,
    settings: paginationParams,
  });

  const tabs = [
    {
      key: '1',
      label: t('tabs.revisions'),
      children: <ConfigurationDetailsRevisions tenantID={tenantID} nodeConfig={nodeConfig} />,
    },
    {
      key: '2',
      label: t('tabs.assignedNodes'),
      children: (
        <NodeConfigNodes
          tenantID={tenantID}
          nodeConfig={nodeConfig}
          nodesQuery={nodesQuery}
          nodesFilters={nodesFilters}
          updateQueryParams={nodesUpdateQueryParams}
          tags={tagsList?.data}
        />
      ),
    },
    {
      key: '3',
      label: t('tabs.jobsHistory'),
      children: (
        <NodeConfigJobsHistory
          nodeConfigID={nodeConfig.id}
          tenantID={tenantID}
          jobsQuery={jobsQuery}
          jobFilters={jobsFilters}
          updateQueryParams={jobsUpdateQueryParams}
        />
      ),
    },
  ];

  return (
    <Flex vertical gap="none">
      <Container>
        <BackButton to={`/tenants/${tenantID}/node-configs`} />
      </Container>
      <NodeConfigurationDetailsHeader nodeConfig={nodeConfig} />
      <Tabs items={tabs} style={{ padding: '16px 0 0 0' }} className="details-tabs" />
    </Flex>
  );
}
