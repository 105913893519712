import { Socket } from 'phoenix';
import { useEffect } from 'react';

import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { useMessage } from '@/hooks/message';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function useWebsocket() {
  const userProfile = useMgmtPlaneStateStore((state) => state.userProfile);
  const socket = useMgmtPlaneStateStore((state) => state.socket);
  const setSocket = useMgmtPlaneStateStore((state) => state.setSocket);
  const [message] = useMessage();

  useEffect(() => {
    (async () => {
      if (!userProfile?.id) return;
      if (socket) return;

      const { data: tokenInfo } = await mgmtPlaneAPIClient.auth.generateToken();
      const { token, tenantID } = tokenInfo;

      const newSocket = new Socket(`${window.mimicConfig.MIMIC_MGMT_PLANE_API}/api/socket`, {
        params: { token, tenantID },
        heartbeatIntervalMs: 1000,
      });
      setSocket(newSocket);

      newSocket.connect();

      newSocket.onError((err) => {
        // eslint-disable-next-line no-console
        console.warn('Websocket connection error', err);
      });
    })();

    return () => {
      if (!socket) return;
      socket.disconnect();
      setSocket(undefined);
    };
  }, [message, socket, userProfile, setSocket]);
}
