/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { StateCreator } from 'zustand';

import { User } from '@/client';

export interface UserProfileSlice {
  userProfile?: User;
  selectedTenantID?: string;
  setProfile: (profile?: User) => void;
  setSelectedTenantID: (tenantID: string) => void;
}

export const selectedTenantIDKey = 'selectedTenantID';
const selectedTenantID = localStorage.getItem(selectedTenantIDKey);

export const createUserProfileSlice: StateCreator<UserProfileSlice, [], [], UserProfileSlice> = (set) => ({
  userProfile: undefined,
  selectedTenantID: selectedTenantID || undefined,
  setProfile: (newProfile) =>
    set((userState) => {
      if (!userState.selectedTenantID) {
        return { userProfile: newProfile, selectedTenantID: newProfile?.tenantID };
      }
      return { userProfile: newProfile };
    }),
  setSelectedTenantID: (tenantID) =>
    set((state) => {
      localStorage.setItem(selectedTenantIDKey, tenantID);
      return { ...state, selectedTenantID: tenantID };
    }),
});
