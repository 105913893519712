/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Outlet } from 'react-router-dom';

import { Col } from '@/primitives/Col';
import { Row } from '@/primitives/Row';
import { SupportFooter } from '@/v1/components/SupportFooter';

import { Layout } from './primitives/Layout';

export function CenterLayout() {
  return (
    <Layout>
      <Col style={{ display: 'flex', flexDirection: 'column' }}>
        <Col style={{ flex: '1' }}>
          <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
            <Outlet />
          </Row>
        </Col>
        <SupportFooter />
      </Col>
    </Layout>
  );
}
