/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Card as AntDCard } from 'antd';
import React from 'react';

import { ContainerSize, SpaceSize, tokens } from '@/theme';

import { Container } from './Container';
import { Title } from './Title';

export type CardProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
  style?: React.CSSProperties;
  padding?: SpaceSize;
  size?: ContainerSize;
  bordered?: boolean;
  dataTestId?: string;
  gap?: SpaceSize;
};

export function Card({ title, style, padding, size, children, bordered, dataTestId, gap }: CardProps) {
  const paddingPx = tokens.size.space[padding || 'xxl'];
  const width = size ? tokens.size.container[size] : undefined;
  const styleOverrides = style || {};
  return (
    <AntDCard
      data-testid={dataTestId}
      styles={{ header: { borderBottom: 0 }, body: { padding: 0 } }}
      style={{ padding: paddingPx, width, ...styleOverrides }}
      bordered={bordered}
    >
      <Container gap={gap || 'md'} style={{ justifyContent: 'stretch' }}>
        {title ? <Title style={{ width: '100%' }}>{title}</Title> : undefined}
        {children}
      </Container>
    </AntDCard>
  );
}
