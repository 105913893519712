/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useLocation, useNavigate } from 'react-router-dom';

import {
  getJobWizardJobName,
  getJobWizardSelectedConfigRevisionInfo,
  getJobWizardSelectedNodes,
} from '@/state/jobWizardStore';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { ConfigureNodesJobWizard } from '@/v1/components/ConfigureNodesJobWizard/ConfigureNodesJobWizard';
import { ConfigureNodesJobWizardSteps } from '@/v1/components/ConfigureNodesJobWizard/ConfigureNodesJobWizardSteps';

export function ConfigureNodesJobWizardRoute() {
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const navigate = useNavigate();
  const location = useLocation();

  if (!tenantID) return null;

  const onCloseWizard = () => {
    navigate(`/tenants/${tenantID}/nodes`);
  };

  const selectedNodes = getJobWizardSelectedNodes();
  const jobName = getJobWizardJobName();
  const selectedConfigRevisionInfo = getJobWizardSelectedConfigRevisionInfo();

  // default to select-nodes if no step is provided or if no nodes are selected
  const currentStep = !selectedNodes
    ? 'select-nodes'
    : (location.hash.slice(1) as ConfigureNodesJobWizardSteps) || 'select-nodes';

  return (
    <ConfigureNodesJobWizard
      tenantID={tenantID}
      currentStep={currentStep}
      onCloseWizard={onCloseWizard}
      selectedNodes={selectedNodes}
      selectedConfigRevisionInfo={selectedConfigRevisionInfo}
      jobName={jobName}
    />
  );
}
