/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { User } from '@/client';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export const useAuthorization = (requiredRole: User['role']) => {
  const user = useMgmtPlaneStateStore((state) => state.userProfile);
  return canPerformAction(requiredRole, user);
};

// Currently, the available roles are viewer, editor, admin and superadmin.
// The way they work is that superadmin users can do everything the other roles
// can do, then admin can do everything editor and viewer can, and editor users
// can do everything viewers can.
//
// In other words, the roles follow a hierarchical structure where each higher-level
// role inherits all the permissions of the roles below it.
export const canPerformAction = (requiredRole: User['role'], user?: User) => {
  if (!user) return false;
  const baseline = authzBaseline[requiredRole] || [];
  return baseline.includes(user.role);
};

const authzBaseline = {
  viewer: ['viewer', 'editor', 'admin', 'superadmin'],
  editor: ['editor', 'admin', 'superadmin'],
  admin: ['admin', 'superadmin'],
  superadmin: ['superadmin'],
};
