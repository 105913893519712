/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { LoadingOutlined } from '@ant-design/icons';

import { Node } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Space } from '@/primitives/Space';
import { antdTokens, tokens } from '@/theme';

export type NodeTransitionIndicatorProps = {
  node: Node;
};

export function NodeTransitionIndicator({ node }: NodeTransitionIndicatorProps) {
  const { t } = useMimicTranslation('node');

  const showTransitionIndicator =
    node.requestedRevisionState?.status === 'pending' &&
    node.appliedRevisionState?.operationalState !== node.requestedRevisionState.operationalState;

  if (!showTransitionIndicator) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: tokens.size.space.sm,
        padding: tokens.size.space.xs,
        backgroundColor: antdTokens.colorFillQuaternary,
        borderColor: tokens.color.border.borderDefault,
        borderWidth: 1,
        borderStyle: 'solid',
      }}
    >
      <Space size="xs" dataTestId="node-transition-indicator">
        <LoadingOutlined />
        {t('operationalStateChanging', {
          currentState: node.appliedRevisionState?.operationalState,
          requestedState: node.requestedRevisionState?.operationalState,
        })}
      </Space>
    </div>
  );
}
