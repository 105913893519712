/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Select, SelectOption, SelectProps } from '@/primitives/Select';
import { Text } from '@/primitives/Text';
import { GetTagListOptionsFn } from '@/v1/utils/hooks/getTagListOptions';
import { useAuthorization } from '@/v1/utils/hooks/useAuthorization';

export type TagsFieldProps = {
  selectedTags: string[];
  getTagListOptions: GetTagListOptionsFn;
  tenantID: string;
  onAddTag: (tagName: string) => Promise<void>;
  onRemoveTag: (tagName: string) => Promise<void>;
  onCreateTag: (name: string) => Promise<void>;
};

export type OptionType = Exclude<SelectProps['options'], undefined>;
const INITIAL_PAGE_SIZE = 8;

const SHOW_MORE = 'show  more';
const ADD_TAG = 'add tag';

export function TagsField({
  selectedTags,
  getTagListOptions,
  tenantID,
  onAddTag,
  onRemoveTag,
  onCreateTag,
}: TagsFieldProps) {
  const { t } = useMimicTranslation('node');
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const [showMore, setShowMore] = useState(false);
  const canManageTags = useAuthorization('editor');

  const { isPending: tagsIsPending, data: tagsData } = useQuery(
    getTagListOptions(tenantID!, {}, [], { size: 100, number: 1 }),
  );

  if (tagsIsPending || !tagsData) return null;
  const { data: tags } = tagsData;

  let visibleTags = tags.map((tag) => tag.name).filter((tag) => tag.includes(localSearchTerm));
  const totalTags = visibleTags.length;
  if (!showMore) {
    visibleTags = visibleTags.slice(0, INITIAL_PAGE_SIZE);
  }
  const selectOptions: SelectOption[] = visibleTags.map((tag) => {
    return {
      label: tag,
      value: tag,
    };
  });

  function onSearchLocal(newSearchTerm: string) {
    setLocalSearchTerm(newSearchTerm.split(' ').join('-').toLowerCase());
  }

  function onDropdownVisibleChange(open: boolean) {
    if (!open) {
      setLocalSearchTerm('');
      setShowMore(false);
    }
  }

  function didSelectOption(options: SelectOption | SelectOption[], value: string) {
    if (Array.isArray(options)) {
      return options.some((option) => option.value === value);
    }
    return options.value === value;
  }

  const onTagsChange = (newSelectedTags: string[], options: SelectOption | SelectOption[]) => {
    const setNew = new Set<string>(newSelectedTags);
    const setOld = new Set<string>(selectedTags);
    const removed = [...setOld].filter((tag) => !setNew.has(tag));
    const added = [...setNew].filter((tag) => !setOld.has(tag));
    if (didSelectOption(options, SHOW_MORE)) {
      setShowMore(true);
      return;
    }

    const createAndAssignTag = async (tagName: string) => {
      await onCreateTag(tagName);
      await onAddTag(tagName);
      setLocalSearchTerm('');
      setShowMore(false);
    };

    if (didSelectOption(options, ADD_TAG)) {
      createAndAssignTag(localSearchTerm);
      return;
    }

    if (removed.length > 0) {
      onRemoveTag(removed[0]);
    }
    if (added.length > 0) {
      onAddTag(added[0]);
      setLocalSearchTerm('');
      setShowMore(false);
    }
  };

  const showMoreNum = totalTags - visibleTags.length;

  const showMoreOption = (
    <Text size="lg" italic type="subtle">
      {t('tagDropdown.showMore', { count: showMoreNum })}
    </Text>
  );

  if (showMoreNum > 0) {
    selectOptions.push({ label: showMoreOption, value: SHOW_MORE });
  }

  const typeToAddTag = (
    <Text size="lg" strong>
      <PlusOutlined style={{ padding: '11px 12px 11px 12px' }} />
      {t('tagDropdown.typeToAddTag')}
    </Text>
  );

  const addTagOption = (
    <Text type="link" data-testid="add-tag-option" strong>
      <PlusOutlined style={{ padding: '8px 12px 8px 0px' }} />
      {t('tagDropdown.addTagOption', { tagName: localSearchTerm })}
    </Text>
  );

  const existingTag = visibleTags.includes(localSearchTerm);

  const showAddTagOption = localSearchTerm && !existingTag;

  const showTypeToAddTag = !localSearchTerm && !existingTag;

  if (showAddTagOption) {
    selectOptions.unshift({ label: addTagOption, value: ADD_TAG });
  }

  return (
    <Select
      data-testid="node-tag-select"
      style={{ width: '100%', minWidth: 200 }}
      mode="multiple"
      placeholder={t('addTag')}
      value={selectedTags}
      searchValue={localSearchTerm}
      onChange={onTagsChange as SelectProps['onChange']}
      onSearch={(val) => onSearchLocal(val)}
      filterOption={false}
      onDropdownVisibleChange={(open) => onDropdownVisibleChange(open)}
      options={selectOptions}
      disabled={!canManageTags}
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={(menu) => (
        <>
          {showTypeToAddTag ? typeToAddTag : null}
          {menu}
        </>
      )}
    />
  );
}
