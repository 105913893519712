/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Container } from '@/primitives/Container';

import { AccountMenu } from './AccountMenu';
import { MenuProps } from './builder';
import { MainMenu } from './MainMenu';

export type LeftMenuProps = MenuProps & {
  children?: React.ReactNode;
};

export function LeftMenu({ tenantID, currentUser, navigate, currentPath, children }: LeftMenuProps) {
  return (
    <Container gap="none" justify="space-between" full>
      <Container.Expand>
        {children}
        <MainMenu tenantID={tenantID} currentUser={currentUser!} navigate={navigate} currentPath={currentPath} />
      </Container.Expand>
      <Container.Fixed>
        <AccountMenu tenantID={tenantID} currentUser={currentUser!} navigate={navigate} currentPath={currentPath} />
      </Container.Fixed>
    </Container>
  );
}
