/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Node, NodeFilters, NodeSortableFields } from '@/client';
import { PaginationParams } from '@/client/mgmtPlane';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Title } from '@/primitives/Title';
import { setJobWizardSelectedNodes } from '@/state/jobWizardStore';
import { fromSorter } from '@/utils/sort';
import { NodesColumns } from '@/v1/components/NodesTable/getNodesColumns';
import { NodesTable, NodesTableProps } from '@/v1/components/NodesTable/NodesTable';
import { getTagListOptions } from '@/v1/utils/hooks/getTagListOptions';
import { useNodesQuery } from '@/v1/utils/hooks/useNodesQuery';

import { ConfigureNodesJobWizardFooter } from './ConfigureNodesJobWizardFooter';

export type ConfigureNodesJobWizardSelectNodesProps = {
  tenantID: string;
  selectedNodes: Node[];
};

export function ConfigureNodesJobWizardSelectNodes({
  tenantID,
  selectedNodes,
}: ConfigureNodesJobWizardSelectNodesProps) {
  const predefinedSelectedIds = selectedNodes?.length ? selectedNodes.map((node) => node.id) : undefined;

  const navigate = useNavigate();
  const { t } = useMimicTranslation('configureNodesJobWizard');
  const { t: tNodes } = useMimicTranslation('nodes');
  const [sameOs, setSameOs] = useState<boolean>(true);
  const [selectedIds, setSelectedIds] = useState<string[]>(predefinedSelectedIds || []);
  const [nodeFilters, setNodeFilters] = useState<Partial<NodeFilters>>({
    id: selectedIds ? selectedIds.join(',') : undefined,
    isOperating: undefined,
  });
  const [showDifferentOsError, setShowDifferentOsError] = useState<boolean | undefined>();
  const [nodeSort, setNodeSort] = useState<NodeSortableFields>([]);
  const [nodePage, setNodePage] = useState<PaginationParams>({ number: 1, size: 10 });
  const nodesQuery = useNodesQuery(tenantID, nodeFilters, nodeSort, nodePage);

  const selectNodeErrorMessage = !selectedIds.length ? t('selectANode') : undefined;
  const notSameOsErrorMessage = showDifferentOsError ? tNodes('banner.differentOSError') : undefined;
  const notSameOsWarningMessage = !sameOs ? tNodes('banner.differentOSWarning') : undefined;
  const errorMessage = selectNodeErrorMessage || notSameOsErrorMessage || notSameOsWarningMessage;

  useEffect(() => {
    if (!selectedNodes?.length) return;
    setSameOs(new Set(selectedNodes.map((node) => node.operatingSystem)).size === 1);
  }, [selectedNodes, setSameOs]);

  const onNext = () => {
    if (!selectedIds.length) return;
    if (!sameOs) {
      setShowDifferentOsError(true);
      return;
    }
    navigate(`#select-config`);
  };

  const updateQueryParams: NodesTableProps['onParamsChange'] = ({ filters, sort, paginationParams }) => {
    const queryFilters: Partial<NodeFilters> = filters || {};
    if (predefinedSelectedIds) queryFilters.id = predefinedSelectedIds.join(',');
    setNodeFilters({ ...queryFilters, isOperating: undefined });
    if (paginationParams) setNodePage(paginationParams);
    if (sort) {
      const sortFields = fromSorter<NodeSortableFields, Node>(sort);
      setNodeSort(sortFields || []);
    }
  };

  // TODO lazy load tags (MP-2350)
  const { data: tagsList } = useQuery(getTagListOptions(tenantID!, {}, ['+name'], { number: 1, size: 100 }));

  const visibleColumns: NodesColumns[] = [
    'hostname',
    'tags',
    'appliedOperationalState',
    'connectivityState',
    'operatingSystem',
    'lastSeen',
    'appliedConfiguration',
  ];

  const getNodeConfigurationPath = (nodeConfigId: string) => `/tenants/${tenantID}/node-configs/${nodeConfigId}`;

  const rowSelection: TableProps<Node>['rowSelection'] = {
    type: 'checkbox',
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedIds,
    onChange: (_selectedKeys: React.Key[], selectedRows: Node[]) => {
      const selectedOses = selectedRows.map((node) => node.operatingSystem);
      const uniqueOses = new Set(selectedOses);
      const newSameOsVal = uniqueOses.size === 1 || selectedRows.length === 0;
      setSameOs(newSameOsVal);
      const newlySelectedIds = selectedRows.map((node) => node.id);
      setSelectedIds(newlySelectedIds);
      setJobWizardSelectedNodes(selectedRows);
      if (newSameOsVal) {
        setShowDifferentOsError(false);
      }
    },
    getCheckboxProps: (node: Node) => ({
      name: node.name,
    }),
  };

  return (
    <>
      <div className="configure-nodes-job-wizard-content">
        <div style={{ padding: '32px', paddingTop: 0, display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Title level={4}>{t('selectNodes')}</Title>
          <NodesTable
            nodesQuery={nodesQuery}
            onParamsChange={updateQueryParams}
            columns={visibleColumns}
            tags={tagsList?.data}
            tenantID={tenantID}
            getNodeConfigurationPath={getNodeConfigurationPath}
            selectedNodeIds={selectedIds}
            rowSelection={rowSelection}
            hideFilters={!!predefinedSelectedIds}
          />
        </div>
      </div>
      <ConfigureNodesJobWizardFooter
        onNext={onNext}
        onNextLabel={t('selectConfig')}
        onNextIcon={null}
        error={!!errorMessage}
        errorMessage={errorMessage}
      />
    </>
  );
}
