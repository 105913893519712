/*
 * Copyright Mimic Networks, Inc. 2024.
 */

type SupportedFilterType = boolean | string | number;
type Filter = Record<string, SupportedFilterType>;

// Note: since we can't infer types of each parameter we must
// have a type map to parse correctly.
// All filter fields are assumed to be nullable and will be undefined
// if not present in query params.
export function getFilters<T>(searchParams: URLSearchParams, types: TypeMap<T>, scope?: string): T {
  const filters: Filter = {};

  Object.entries<QueryParamTypes>(types).forEach(([k, type]) => {
    const filtersFields = scope ? `${scope}Filters` : 'filters';
    const val = searchParams.get(`${filtersFields}[${k}]`);
    if (val != null) {
      switch (type) {
        case 'boolean':
          filters[k] = val === 'true';
          break;
        case 'number':
          filters[k] = Number(val);
          break;
        case 'string':
          filters[k] = val;
          break;
        default:
          break;
      }
    }
  });

  return filters as T;
}

export function setFilters<T extends object>(searchParams: URLSearchParams, filters: T, scope?: string): void {
  const f = filters as Filter;
  const filtersFields = scope ? `${scope}Filters` : 'filters';

  Object.keys(filters).forEach((k) => {
    const val = f[k];
    if (val !== null && val !== '' && val !== undefined) {
      searchParams.set(`${filtersFields}[${k}]`, String(f[k]));
    } else {
      searchParams.delete(`${filtersFields}[${k}]`);
    }
  });
}

export type QueryParamTypes = 'string' | 'boolean' | 'number';
export type TypeMap<T> = { [key in keyof T]-?: QueryParamTypes };

export function defineTypes<T>(types: TypeMap<T>): TypeMap<T> {
  return types;
}
