/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ColumnsType } from 'antd/es/table/interface';

import { Notification } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Link } from '@/primitives/Link';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';
import { UpdateParams } from '@/utils/params';
import { PageHeader } from '@/v1/components/PageHeader';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { RelativeTime } from '@/v1/components/RelativeTime';
import { TextBadge } from '@/v1/components/TextBadge';
import { GetNotificationListOptions } from '@/v1/utils/hooks/getNotificationListOptions';
import { useSubscribeToEvents } from '@/v1/utils/hooks/useSubscribeToEvents';

export type NotificationsListPageParams = {
  options: GetNotificationListOptions;
  onParamsChange: UpdateParams<Notification>;
  tenantID: string | undefined;
};

export type NotificationType =
  | 'NODE_PROVISIONED_V0'
  | 'NODE_CONFIG_APPLIED_V0'
  | 'NODE_CONFIG_REJECTED_V0'
  | 'NODE_CREDENTIAL_APPLIED_V0'
  | 'NODE_CREDENTIAL_REJECTED_V0'
  | 'CONFIG_HEARTBEAT_RESUMED_V0'
  | 'CONFIG_HEARTBEAT_STOPPED_V0'
  | 'CREDENTIAL_HEARTBEAT_RESUMED_V0'
  | 'CREDENTIAL_HEARTBEAT_STOPPED_V0'
  | 'MEMBER_EDITED_V0'
  | 'MEMBER_ADDED_V0'
  | 'NODE_LIFECYCLE_ERROR_V0'
  | 'HALLMARK'
  | 'DEFLECTION';

type NotificationTemplate = {
  title: (notification: Notification) => JSX.Element | string;
  subtitle: (notification: Notification) => JSX.Element | string;
};

type NotificationTemplateLookup = {
  [key in NotificationType]: NotificationTemplate;
};

const NODE_NOTIFICATION_TEMPLATE: NotificationTemplate = {
  title: (n: Notification) => n.title,
  subtitle: (n: Notification) => {
    const data = n.subjectData;
    const nodePath = `/tenants/${n.tenantID}/nodes/${data.nodeId}`;
    return (
      <>
        {n.subtitle} in{' '}
        <Link to={nodePath}>
          {data.hostname} | {data.name}
        </Link>
      </>
    );
  },
};

const MEMBER_NOTIFICATION_TEMPLATE: NotificationTemplate = {
  title: (n: Notification) => n.title,
  subtitle: (n: Notification) => {
    return (
      <>
        {n.subjectData.displayName} {n.subtitle}
      </>
    );
  },
};

const FALLBACK_NOTIFICATION_CONTENT: NotificationTemplate = {
  title: (n: Notification) => n.title,
  subtitle: (n: Notification) => n.subtitle,
};

const NOTIFICATION_CONTENT: NotificationTemplateLookup = {
  NODE_PROVISIONED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_CREDENTIAL_APPLIED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_CONFIG_APPLIED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_CONFIG_REJECTED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_CREDENTIAL_REJECTED_V0: NODE_NOTIFICATION_TEMPLATE,
  CREDENTIAL_HEARTBEAT_STOPPED_V0: NODE_NOTIFICATION_TEMPLATE,
  CREDENTIAL_HEARTBEAT_RESUMED_V0: NODE_NOTIFICATION_TEMPLATE,
  CONFIG_HEARTBEAT_STOPPED_V0: NODE_NOTIFICATION_TEMPLATE,
  CONFIG_HEARTBEAT_RESUMED_V0: NODE_NOTIFICATION_TEMPLATE,
  NODE_LIFECYCLE_ERROR_V0: NODE_NOTIFICATION_TEMPLATE,
  HALLMARK: NODE_NOTIFICATION_TEMPLATE,
  DEFLECTION: NODE_NOTIFICATION_TEMPLATE,
  MEMBER_ADDED_V0: MEMBER_NOTIFICATION_TEMPLATE,
  MEMBER_EDITED_V0: MEMBER_NOTIFICATION_TEMPLATE,
};

function notificationContent(notification: Notification) {
  const type = notification.type as NotificationType;
  const { title, subtitle } = NOTIFICATION_CONTENT[type] || FALLBACK_NOTIFICATION_CONTENT;
  return {
    title: title(notification),
    subtitle: subtitle(notification),
  };
}

function urgency(notification: Notification) {
  if (notification.type === 'HALLMARK' || notification.type === 'DEFLECTION') {
    return 'critical';
  }
  return 'informational';
}

export function NotificationsListPage({ options, onParamsChange, tenantID }: NotificationsListPageParams) {
  const { t } = useMimicTranslation('notifications');
  const notificationsQuery = useQuery(options);
  const queryClient = useQueryClient();

  useSubscribeToEvents(['notification:created', 'node:connectivity-updated'], () => {
    queryClient.invalidateQueries({
      queryKey: ['notifications', tenantID],
    });
  });

  const columns: ColumnsType<Notification> = [
    {
      title: t('columns.notification'),
      dataIndex: 'title',
      key: 'title',
      render: (_title: string, notification: Notification) => {
        const { title, subtitle } = notificationContent(notification);
        return (
          <Space dir="vertical">
            <Text size="md" data-testid="title" strong>
              {title}
            </Text>
            <Text size="md" data-testid="subtitle">
              {subtitle}
            </Text>
          </Space>
        );
        return title;
      },
    },
    {
      title: t('columns.urgency'),
      dataIndex: 'subjectData',
      key: 'urgency',
      align: 'left',
      render: (_, n: Notification) => {
        const urgencyLevel = urgency(n);
        if (urgencyLevel === 'critical') {
          return <TextBadge status="error">Critical</TextBadge>;
        }
        return <TextBadge status="info">Informational</TextBadge>;
      },
    },
    {
      title: t('columns.timestamp'),
      dataIndex: 'dateOfEvent',
      key: 'dateOfEvent',
      render: (dateOfEvent) => {
        return <RelativeTime date={dateOfEvent} />;
      },
    },
    {
      title: t('columns.channels'),
      dataIndex: 'subjectData',
      key: 'channels',
      render: (_, n: Notification) => {
        const urgencyLevel = urgency(n);
        if (urgencyLevel === 'critical') {
          return `External`;
        }
        return `-`;
      },
    },
  ];

  return (
    <Container>
      <PageHeader title={t('title')} level={2} />
      <PaginatedTable
        paginatedResource={notificationsQuery}
        rowKey={(notification) => notification.id}
        columns={columns}
        onParamsChange={onParamsChange}
      />
    </Container>
  );
}
