/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { MouseEventHandler, ReactNode } from 'react';

import { User } from '@/client';
import { Link } from '@/primitives/Link';
import { MenuDefProps } from '@/primitives/Menu';

export type MenuItem = Required<MenuDefProps>['items'][number];

export type MenuItemConfig = {
  icon?: ReactNode;
  danger?: boolean;
  name: string;
  label: ReactNode;
  path?: string;
  onClick?: () => void;
  dataTestId?: string;
  items?: MenuItemConfig[];
  type?: 'divider';
  requiredRole?: User['role'];
};

export type NavigateCallback = (path: string) => void;

export type MenuKeyPath = {
  defaultSelectedKeys: string[];
  defaultOpenKeys: string[];
};

export type MenuProps = {
  tenantID: string;
  currentUser: User;
  navigate: NavigateCallback;
  currentPath: string;
};

function keyForMenuItem(config: MenuItemConfig): string {
  const key = config.name?.toLocaleLowerCase().replace(/ +/, '-');
  return `item-${key}`;
}

// Turns MenuConfig above into easily traversable pathname to submenu key map.
// This is used when the menu is rendered on a nested menu item route.
// ex:
// {
//   '/a': {
//     defaultSelectedKeys: ['item-a'],
//     defaultOpenKeys: [],
//   },
//   '/b/c': {
//     defaultSelectedKeys: ['item-c'],
//     defaultOpenKeys: ['item-b'],
//   }
// }
export function configToKeyMap(currentPath: string[], menuItemConfigs: MenuItemConfig[]): Record<string, MenuKeyPath> {
  return menuItemConfigs.reduce((acc, item) => {
    const key = keyForMenuItem(item);
    const selfMap: Record<string, MenuKeyPath> | Record<string, never> = item.path
      ? { [item.path]: { defaultSelectedKeys: [key], defaultOpenKeys: currentPath } }
      : {};
    const childMap = item.items ? configToKeyMap(currentPath.concat(key), item.items) : {};
    return {
      ...acc,
      ...selfMap,
      ...childMap,
    };
  }, {});
}

// Menu items use the supplied `navigate` prop rather than call the default link click action.
// This is useful for testing and for handling global navigation rules.
function navigateHandler(path: string, navigate: NavigateCallback): MouseEventHandler<HTMLElement> {
  return (event) => {
    event.preventDefault();
    navigate(path);
  };
}

// Recursively create `MenuItem`s based on the menu item config
export function configToMenuItem(config: MenuItemConfig, navigate: NavigateCallback): MenuItem {
  const key = keyForMenuItem(config);
  const { path, onClick, icon, danger, label, items, dataTestId } = config;
  const children = items ? items.map((item) => configToMenuItem(item, navigate)) : undefined;
  const linkOrLabel =
    !path && !onClick ? (
      <span data-testid={dataTestId}>{label}</span>
    ) : (
      <Link
        dataTestId={dataTestId}
        style={{ fontSize: 'inherit', textDecoration: 'none' }}
        onClick={(e) => {
          if (path) {
            const onNavigate = navigateHandler(path, navigate);
            onNavigate(e);
          } else if (onClick) {
            onClick();
          }
        }}
        to={path}
      >
        {label}
      </Link>
    );
  if (children) {
    return {
      key,
      icon,
      danger,
      label: linkOrLabel,
      children,
    };
  }
  if (config.type === 'divider') return { type: 'divider' };

  return {
    key,
    icon,
    danger,
    label: linkOrLabel,
    children,
  };
}

export function buildMenu(menu: MenuItemConfig[], currentPath: string, navigate: NavigateCallback) {
  const menuKeys: Record<string, MenuKeyPath> = configToKeyMap([], menu);
  const items = menu.map((item) => configToMenuItem(item, navigate));
  const selected = menuKeys[currentPath] || {
    defaultSelectedKeys: [],
    defaultOpenKeys: [],
  };
  return { items, ...selected };
}
