/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { NodeHallmark } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { UpdateParams } from '@/utils/params';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { getHallmarks } from '@/v1/utils/hooks/getHallmarks';

export type HallmarksProps = {
  options: ReturnType<typeof getHallmarks>;
  onParamsChange: UpdateParams<NodeHallmark, unknown>;
};

export function Hallmarks({ options, onParamsChange }: HallmarksProps) {
  const { t } = useMimicTranslation('node');
  const hallmarksQuery = useQuery(options);

  const columns: ColumnsType<NodeHallmark> = [
    {
      title: t('hallmarksTable.mimicID'),
      dataIndex: 'mimicID',
      key: 'mimicID',
    },
    {
      title: t('hallmarksTable.mimicDescription'),
      dataIndex: 'mimicDescription',
      key: 'mimicDescription',
    },
    {
      title: t('hallmarksTable.occurrences'),
      dataIndex: 'occurrences',
      key: 'occurrences',
    },
    {
      title: t('hallmarksTable.firstSeen'),
      dataIndex: 'firstSeen',
      key: 'firstSeen',
      render: (date: string) => dayjs(date).utc().format('DD MMM YYYY HH:mm:ss'),
    },
    {
      title: t('hallmarksTable.lastSeen'),
      dataIndex: 'lastSeen',
      key: 'lastSeen',
      render: (date: string) => dayjs(date).utc().format('DD MMM YYYY HH:mm:ss'),
    },
  ];

  return (
    <PaginatedTable
      paginatedResource={hallmarksQuery}
      columns={columns}
      rowKey={(hallmark) => hallmark.mimicID}
      style={{ width: '100%' }}
      onParamsChange={onParamsChange}
    />
  );
}
