/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Form } from 'antd';

import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Card } from '@/primitives/Card';
import { Input } from '@/primitives/Input';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

export type ConfigureNodesJobWizardReviewJobNameCardProps = {
  jobName: string | undefined;
  setJobName: (jobName: string) => void;
  error: boolean;
  setError: (error: boolean) => void;
};

export function ConfigureNodesJobWizardReviewJobNameCard({
  jobName,
  setJobName,
  error,
  setError,
}: ConfigureNodesJobWizardReviewJobNameCardProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');
  const [form] = Form.useForm();

  const onJobNameChange = () => {
    const name = form.getFieldValue('job-name');
    setJobName(name);
    if (name.length) {
      setError(false);
    }
  };

  return (
    <div>
      <div style={{ width: '100%', backgroundColor: '#E4F7FC' }} data-testid="banner">
        <ExclamationCircleFilled
          style={{ color: tokens.color.info.infoDefault, fontSize: '1rem', padding: '12px' }}
          data-testid="bang-icon"
        />
        <Text type="default" size="md">
          {t('configuringNodesCreates')}
        </Text>
      </div>
      <Card style={{ width: '100%', padding: '12px 16px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', width: '100%' }}>
          <Text type="subtle" size="md" strong>
            {t('jobName')}
          </Text>
          <Form form={form} onFinish={() => {}} autoComplete="off" style={{ width: '100%' }} data-testid="form">
            <Form.Item
              name="job-name"
              initialValue={jobName}
              style={{ marginBottom: '1.5rem' }}
              help={
                error ? (
                  <div style={{ display: 'flex', alignItems: 'center', padding: '6px 0' }}>
                    <ExclamationCircleFilled
                      style={{ color: tokens.color.error.error, fontSize: '0.875rem', marginRight: '4px' }}
                      data-testid="bang-icon"
                    />
                    <Text type="error" size="md">
                      {t('jobNameRequired')}
                    </Text>
                  </div>
                ) : undefined
              }
              validateStatus={error ? 'error' : undefined}
            >
              <Input
                autoFocus
                dataTestId="job-name-input"
                style={{
                  padding: '0.5625rem 0.875rem',
                  fontSize: '1.1875rem',
                  lineHeight: '1.75rem',
                  backgroundColor: '#ffffff',
                }}
                allowClear
                onBlur={onJobNameChange}
              />
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
}
