/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CheckCircleFilled } from '@ant-design/icons';

import { tokens } from '@/theme';

export type SuccessIconParams = {
  on?: 'toast';
};

const COLOR_MAP = {
  default: tokens.color.success.successDefault,
  toast: tokens.color.components.message.successOnToastBg,
};

export function SuccessIcon({ on }: SuccessIconParams) {
  const color = COLOR_MAP[on || 'default'];
  return <CheckCircleFilled style={{ color }} />;
}
