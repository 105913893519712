/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';
import { ColumnType } from 'antd/es/table';

import { Node, NodeConfig, NodeConfigFilters, PaginatedNodeConfigsResponse } from '@/client';
import { MimicTranslationFunction, useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Card } from '@/primitives/Card';
import { Flex } from '@/primitives/Flex';
import { OsIcon } from '@/primitives/Icons';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { tokens } from '@/theme';
import { UpdateParams } from '@/utils/params';
import MimicHighlighter from '@/v1/components/MimicHighlighter';
import { PaginatedTable } from '@/v1/components/PaginatedTable';
import { RelativeTime } from '@/v1/components/RelativeTime';
import { TagsList } from '@/v1/components/TagsList';

import { ExpandedConfigRowRender } from './ExpandedConfigRowRender';

export type ConfigureNodesJobWizardSelectConfigRevisionMainProps = {
  tenantID: string;
  selectedNodes: Pick<Node, 'id' | 'operatingSystem'>[];
  nodeConfigsQuery: UseQueryResult<PaginatedNodeConfigsResponse, Error>;
  updateQueryParams: UpdateParams<NodeConfig> | undefined;
  selectedConfigRevisionInfo: { configId: string; revisionId: string; revisionNumber: number };
  setShowConfigCode: (showConfigCode: boolean) => void;
  setError: (error: boolean) => void;
};

export function ConfigureNodesJobWizardSelectConfigRevisionMain({
  tenantID,
  selectedNodes,
  nodeConfigsQuery,
  updateQueryParams,
  selectedConfigRevisionInfo,
  setShowConfigCode,
  setError,
}: ConfigureNodesJobWizardSelectConfigRevisionMainProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');

  const expandedRowRender = (record: NodeConfig) => (
    <ExpandedConfigRowRender
      tenantID={tenantID}
      nodeConfig={record}
      selectedConfigRevisionInfo={selectedConfigRevisionInfo}
      setError={setError}
      setShowConfigCode={setShowConfigCode}
    />
  );

  const filters = nodeConfigsQuery.data?.meta.filters;
  const columns = getColumns(t, filters);

  return (
    <div style={{ width: '100%' }}>
      <Card padding="sm" style={{ width: '100%', borderBottom: 'none' }} gap="xs">
        <Title level={5}>{t('selectConfig')}</Title>
        <Flex gap="xs" align="center">
          <OsIcon name={selectedNodes[0].operatingSystem} color={tokens.color.text.textSubtle} />
          <Text type="subtle" size="md">
            {selectedNodes[0].operatingSystem.slice(0, 1).toUpperCase() + selectedNodes[0].operatingSystem.slice(1)}
          </Text>
        </Flex>
      </Card>
      <PaginatedTable
        rowKey={(record) => record.id}
        columns={columns}
        paginatedResource={nodeConfigsQuery}
        data-testid="config-table"
        onParamsChange={updateQueryParams}
        expandable={{
          expandedRowRender,
          rowExpandable: () => true,
          expandRowByClick: true,
          defaultExpandedRowKeys: selectedConfigRevisionInfo.configId
            ? [selectedConfigRevisionInfo.configId]
            : undefined,
        }}
      />
    </div>
  );
}

function getColumns(
  t: MimicTranslationFunction<'configureNodesJobWizard'>,
  filters?: NodeConfigFilters,
): ColumnType<NodeConfig>[] {
  const columns: ColumnType<NodeConfig>[] = [
    {
      title: <span data-testid="config-name">{t('configName')}</span>,
      dataIndex: 'name',
      sorter: true,
      filterMode: 'menu',
      render: (_, config) => {
        return <MimicHighlighter searchText={filters?.name} text={config.name} />;
      },
    },
    {
      title: <span data-testid="tags">Tags</span>,
      dataIndex: 'tags',
      width: '200px',
      render: (tags: string[]) => {
        return <TagsList tags={tags} />;
      },
    },
    {
      title: <span data-testid="assigned-to">{t('assignedTo')}</span>,
      dataIndex: 'useCount',
      sorter: true,
      render: (_, config: NodeConfig) => {
        return <Text type="default">{`${config.useCount} ${t('nodesLower')}`}</Text>;
      },
    },
    {
      title: <span data-testid="last-revised">{t('lastRevised')}</span>,
      dataIndex: 'dateUpdated',
      sorter: true,
      render: (_, config: NodeConfig) => <RelativeTime date={config.dateUpdated} />,
      width: '172px',
    },
  ];
  return columns;
}
