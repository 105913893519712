/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import { Pagination } from '@/client';
import { PaginationParams } from '@/client/mgmtPlane';

import { Order, Sorter, UpdateParams } from './params';

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 10;
const URL_PAGE_PARAM = 'page';
const URL_PAGE_SIZE_PARAM = 'pageSize';

export function getPaginationParams(searchParams: URLSearchParams, scope?: string): PaginationParams {
  const number = parseInt(searchParams.get(scope ? `${scope}Page` : URL_PAGE_PARAM) || '1', 10);
  const size = parseInt(
    searchParams.get(scope ? `${scope}PageSize` : URL_PAGE_SIZE_PARAM) || `${DEFAULT_PAGE_SIZE}`,
    10,
  );

  return {
    number,
    size,
  };
}

export function setPaginationParams(searchParams: URLSearchParams, params: PaginationParams, scope?: string) {
  searchParams.set(scope ? `${scope}Page` : URL_PAGE_PARAM, params.number?.toString() || '1');
  searchParams.set(scope ? `${scope}PageSize` : URL_PAGE_SIZE_PARAM, params.size?.toString() || `${DEFAULT_PAGE_SIZE}`);
}

export function paginationDefaults(pagination?: Pagination): Pagination {
  return pagination || { currentPage: 1, pageSize: 10, totalItems: 0, totalPages: 0 };
}

export function buildTablePaginator<T, F = object>(onParamsChange?: UpdateParams<T, F>, baseFilters?: F) {
  if (!onParamsChange) return undefined;

  return (pageConfig: TablePaginationConfig, filters: F, sorter: SorterResult<T> | SorterResult<T>[]) => {
    const allFilters = { ...(baseFilters || {}), ...filters };
    const sorterArray = Array.isArray(sorter) ? sorter : [sorter];
    const sort = sorterArray.map<Sorter<T>>((s) => {
      return { field: s.field as keyof T, order: s.order as Order };
    });

    onParamsChange({
      paginationParams: {
        number: pageConfig.current || DEFAULT_PAGE_NUMBER,
        size: pageConfig.pageSize || DEFAULT_PAGE_SIZE,
      },
      sort,
      filters: allFilters,
    });
  };
}

export type PaginationRange = {
  invalid: boolean;
  start: number;
  end: number;
  total: number;
};

export function paginationRange(pagination: Pagination): PaginationRange {
  const page = pagination.currentPage;
  const pages = pagination.totalPages;
  const size = pagination.pageSize;
  const total = pagination.totalItems;

  if (page > pages && total > 0) {
    return { start: 0, end: 0, total: 0, invalid: true };
  }

  let start = (page - 1) * size + 1;
  let end = page * size;

  if (start > total) {
    start = total;
  }
  if (end > total) {
    end = total;
  }

  return {
    invalid: false,
    start,
    end,
    total,
  };
}
