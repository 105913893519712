import { HTTPError } from '@/v1/components/HTTPError';
import { useAuthorization } from '@/v1/utils/hooks/useAuthorization';

import { User } from './client';

export type GuardedRouteProps = {
  requiredRole: User['role'];
  children: React.ReactNode;
};

export function GuardedRoute({ children, requiredRole }: GuardedRouteProps) {
  const canView = useAuthorization(requiredRole);

  if (!canView) {
    return <HTTPError httpStatus="404" />;
  }

  return children;
}
