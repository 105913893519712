/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ReactNode } from 'react';

import { ErrorIcon } from '@/primitives/Icons';
import { Space } from '@/primitives/Space';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

export function ValidationError({ children }: { children: ReactNode }) {
  return (
    <div style={{ padding: '5px 0', fontSize: tokens.size.font.md }}>
      <Space>
        <ErrorIcon />
        <Text size="md" type="error">
          {children}
        </Text>
      </Space>
    </div>
  );
}
