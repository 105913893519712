/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ConfigRevision, NodeConfig } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Card } from '@/primitives/Card';
import { Flex } from '@/primitives/Flex';
import { OsIcon } from '@/primitives/Icons';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { AvatarAndName } from '@/v1/components/AvatarAndName';
import { JobDetailsVerticalDivider } from '@/v1/components/JobDetails/JobDetailsVerticalDivider';

export type ConfigureNodesJobWizardReviewJobConfigProps = {
  tenantID: string;
  selectedConfig: NodeConfig | undefined;
  selectedRevision: ConfigRevision | undefined;
};

export function ConfigureNodesJobWizardReviewJobConfig({
  tenantID,
  selectedConfig,
  selectedRevision,
}: ConfigureNodesJobWizardReviewJobConfigProps) {
  const { t } = useMimicTranslation('jobs');

  if (!selectedConfig || !selectedRevision) {
    return null;
  }

  return (
    <Card padding="md" gap="sm">
      <Title level={3} style={{ fontSize: '17px', fontWeight: '700' }}>
        {t('targetConfig')}
      </Title>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
          <Link to={`/tenants/${tenantID}/node-configs/${selectedConfig.id}`}>
            <Text mono>{selectedConfig.name}</Text>
          </Link>
          <Flex gap="xs">
            <Text size="sm" type="subtle" style={{ textTransform: 'capitalize' }}>
              <OsIcon name={selectedConfig.os} /> {selectedConfig.os}
            </Text>
            <JobDetailsVerticalDivider />
            <Text mono copyable type="subtle" size="sm">
              {selectedConfig.id}
            </Text>
          </Flex>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
          <Link
            to={`/tenants/${tenantID}/node-configs/${selectedConfig.id}/revisions/${selectedRevision.revisionNumber}`}
          >
            <Text data-testid="revision-title">
              {t('revision')} #{selectedRevision.revisionNumber}: {selectedRevision.description}
            </Text>
          </Link>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <AvatarAndName size="sm" userInfo={selectedRevision.createdBy} />
            <JobDetailsVerticalDivider />
            <Text mono copyable type="subtle" size="sm">
              {selectedRevision.id}
            </Text>
          </div>
        </div>
      </div>
    </Card>
  );
}
