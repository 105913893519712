/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import {
  CheckCircleFilled,
  CloseOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { useState } from 'react';

import { Job } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { useAuthorization } from '@/v1/utils/hooks/useAuthorization';
import { useUpdateJobMutation } from '@/v1/utils/hooks/useUpdateJobMutation';

import './JobBanner.css';

export type JobBannerProps = {
  job: Job;
};

const statusIcon = {
  running: <LoadingOutlined data-testid="job-running-icon" />,
  success: <CheckCircleFilled data-testid="job-success-icon" />,
  warning: <WarningFilled data-testid="job-warning-icon" />,
  error: <ExclamationCircleFilled data-testid="job-error-icon" />,
  timeout: <ExclamationCircleFilled data-testid="job-timeout-icon" />,
};

export function JobBanner({ job }: JobBannerProps) {
  const { t } = useMimicTranslation('jobs');
  const updateJob = useUpdateJobMutation(job);
  const [showJobBanner, setShowJobBanner] = useState(!job.notificationDismissed);
  const canDismissJobs = useAuthorization('admin');

  const showDismissNotification = job.nodeStatus.running === 0 && canDismissJobs;
  const status = getConveyedStatus(job);
  const jobBannerClasses = ['job-banner', `job-${status}`].join(' ');

  const dismissJobNotification = () => {
    if (!showDismissNotification) return;

    updateJob.mutate(
      { notificationDismissed: true },
      {
        onSuccess: () => {
          setShowJobBanner(false);
        },
      },
    );
  };

  if (!showJobBanner) return null;

  return (
    <div data-testid="job-banner" className={jobBannerClasses}>
      <div className="job-banner-description">
        {statusIcon[status]}
        <Text size="md" strong>
          {job.name}
        </Text>
        <div className="job-banner-config">
          <Text size="md" mono>
            {job.targetConfig.nodeConfig.name}
          </Text>
          <Text size="md">·</Text>
          <Text size="md">
            {t('revision')} #{job.targetConfig.configRevision.revisionNumber}
          </Text>
        </div>
      </div>
      <div className="job-banner-status">
        <JobNodeStatus status="error" count={job.nodeStatus.error + job.nodeStatus.timeout} />
        <JobNodeStatus status="warning" count={job.nodeStatus.warning} />
        <JobNodeStatus status="success" count={job.nodeStatus.success} />
        <JobNodeStatus status="running" count={job.nodeStatus.running} />
        <Link to={`/tenants/${job.tenantId}/jobs/${job.id}`} style={{ textDecoration: 'none' }}>
          <Text size="md" data-testid="view-job" strong>
            {t('viewJob')}
          </Text>
        </Link>
        {showDismissNotification && <CloseOutlined data-testid="dismiss-job" onClick={dismissJobNotification} />}
      </div>
    </div>
  );
}

function JobNodeStatus({ status, count }: { status: Job['status']; count: number }) {
  if (count === 0) return null;

  return (
    <div className={`job-banner-status-${status}`} data-testid={`job-banner-status-${status}`}>
      {statusIcon[status]}
      <Text size="md">{count}</Text>
    </div>
  );
}

// The idea is that, while running, we might show the banner with an error/warning style if there are any errors or warnings.
// The rules are:
// - not running? show the status as is
// - running + some error? show error
// - running + some warning? show warning
// - running + some success? show running
function getConveyedStatus(job: Job) {
  if (job.status !== 'running') return job.status;
  if (job.nodeStatus.error > 0 || job.nodeStatus.timeout > 0) return 'error';
  if (job.nodeStatus.warning > 0) return 'warning';
  if (job.nodeStatus.success > 0) return 'running';
  return job.status;
}
