/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { InfoCircleFilled } from '@ant-design/icons';

import { tokens } from '@/theme';

export type InfoIconParams = {
  on?: 'toast';
};

const COLOR_MAP = {
  default: tokens.color.info.infoDefault,
  toast: tokens.color.components.message.infoOnToastMessageBg,
};

export function InfoIcon({ on }: InfoIconParams) {
  const color = COLOR_MAP[on || 'default'];
  return <InfoCircleFilled style={{ color }} />;
}
