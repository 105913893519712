/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { queryOptions } from '@tanstack/react-query';

import { NotificationFilters } from '@/client';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';

export function getNotificationListOptions(tenantId: string, filters: NotificationFilters, settings: PaginationParams) {
  return queryOptions({
    queryKey: ['notifications', tenantId, settings.number, settings.size] as (string | number | undefined)[],
    queryFn: () =>
      mgmtPlaneAPIClient.notifications.getNotifications({
        tenantId,
        page: settings.number,
        pageSize: settings.size,
        filters,
      }),
    enabled: Boolean(tenantId),
  });
}

export type GetNotificationListOptions = ReturnType<typeof getNotificationListOptions>;
