/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'node_modules/@tanstack/react-query/build/legacy';

import { ApiError, NodeTagResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type NewAddTagMutationConfig = {
  onSuccess?: (data: NodeTagResponse, params: AddNodeTag) => void;
  onError?: (error: ApiError, params: AddNodeTag) => void;
  onSettled?: (data: NodeTagResponse | undefined, error: ApiError | null, params: AddNodeTag) => void;
};

export type AddNodeTag = {
  nodeId: string;
  tagName: string;
};

export function getAddTagMutationOptions(tenantId: string, queryClient: QueryClient, config?: NewAddTagMutationConfig) {
  return {
    mutationFn: (params: AddNodeTag) => {
      return mgmtPlaneAPIClient.nodes.addNodeTag({ tenantId, id: params.nodeId, tagName: params.tagName });
    },
    onSuccess: async (data, params) => {
      if (config?.onSuccess) {
        await config.onSuccess(data, params);
      }
      queryClient.invalidateQueries({ queryKey: ['node', tenantId, params.nodeId] });
      queryClient.invalidateQueries({ queryKey: ['nodes'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  } satisfies UseMutationOptions<NodeTagResponse, ApiError, AddNodeTag>;
}

export function useAddTagMutation(tenantID: string, config?: NewAddTagMutationConfig) {
  const queryClient = useQueryClient();
  return useMutation<NodeTagResponse, ApiError, AddNodeTag>(getAddTagMutationOptions(tenantID, queryClient, config));
}
