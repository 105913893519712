/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';

import { ConfigRevisionFilters, ConfigRevisionsSortableFields } from '@/client';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';

export type GetConfigRevisionParams = {
  tenantId?: string;
  configId?: string;
  filters?: ConfigRevisionFilters;
  sort?: ConfigRevisionsSortableFields;
  settings?: PaginationParams;
};

export function useGetConfigRevisionsQuery({ tenantId, configId, filters, sort, settings }: GetConfigRevisionParams) {
  const pageSettings = settings || { number: 1, size: 10 };
  return useQuery({
    queryKey: ['config-revisions', configId],
    queryFn: async () => {
      return mgmtPlaneAPIClient.nodeConfigs.getConfigRevisions({
        tenantId: tenantId!,
        configId: configId!,
        filters,
        sort,
        page: pageSettings.number,
        pageSize: pageSettings.size,
      });
    },
    enabled: !!tenantId && !!configId,
  });
}
