/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ConfigRevision, NodeConfig } from '@/client/';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Radio } from '@/primitives/Radio';
import { Text } from '@/primitives/Text';
import { setJobWizardSelectedConfigRevisionInfo } from '@/state/jobWizardStore';
import { RelativeTime } from '@/v1/components/RelativeTime';
import { useConfigRevisionsQuery } from '@/v1/utils/hooks/useConfigRevisionsQuery';

export type ExpandedRowProps = {
  tenantID: string;
  nodeConfig: NodeConfig;
  selectedConfigRevisionInfo: { configId: string; revisionId: string; revisionNumber: number };
  setShowConfigCode: (showConfigCode: boolean) => void;
  setError: (error: boolean) => void;
};

export function ExpandedConfigRowRender({
  tenantID,
  nodeConfig,
  selectedConfigRevisionInfo,
  setShowConfigCode,
  setError,
}: ExpandedRowProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');

  const configRevisionsQuery = useConfigRevisionsQuery({
    tenantId: tenantID,
    configId: nodeConfig.id,
    filters: {},
    sort: [],
    settings: { number: 1, size: 10 },
  });

  const revisions = configRevisionsQuery.data?.data || [];

  const onSelectConfig = (configRevision: ConfigRevision) => {
    setError(false);

    setJobWizardSelectedConfigRevisionInfo({
      configId: nodeConfig.id,
      revisionId: configRevision.id,
      revisionNumber: configRevision.revisionNumber,
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {revisions.map((revision) => (
        <Flex key={revision.id} full align="center">
          <Flex full align="center" gap="xs">
            <Radio
              dataTestId="select-config-revision"
              onClick={() => setShowConfigCode(true)}
              onChange={() => onSelectConfig(revision)}
              checked={selectedConfigRevisionInfo?.revisionId === revision.id}
            />
            <Text type="subtle" size="sm" style={{ minWidth: '44px', maxWidth: '72px' }}>
              {`${t('rev')} #${revision.revisionNumber}`}
            </Text>
            <Text type="default" size="sm">
              {revision.description}
            </Text>
          </Flex>
          <div style={{ width: '164px' }}>
            <Text type="default" size="sm">
              <RelativeTime date={revision.createdAt} />
            </Text>
          </div>
        </Flex>
      ))}
    </div>
  );
}
