/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Select as AntDSelect, SelectProps as AntDSelectProps } from 'antd';
import './Select.css';

export type SelectOption = {
  label?: React.ReactNode;
  value?: string;
};

export type SelectProps = {
  showSearch?: AntDSelectProps['showSearch'];
  open?: AntDSelectProps['open'];
  onChange?: AntDSelectProps['onChange'];
  onSearch?: AntDSelectProps['onSearch'];
  onBlur?: AntDSelectProps['onBlur'];
  onDropdownVisibleChange?: AntDSelectProps['onDropdownVisibleChange'];
  onFocus?: AntDSelectProps['onFocus'];
  filterOption?: AntDSelectProps['filterOption'];
  placeholder?: AntDSelectProps['placeholder'];
  defaultValue?: AntDSelectProps['defaultValue'];
  value?: AntDSelectProps['value'];
  searchValue?: AntDSelectProps['searchValue'];
  mode?: AntDSelectProps['mode'];
  options?: AntDSelectProps['options'];
  style?: AntDSelectProps['style'];
  dropdownRender?: AntDSelectProps['dropdownRender'];
  fieldNames?: AntDSelectProps['fieldNames'];
  labelRender?: AntDSelectProps['labelRender'];
  ['data-testid']?: string;
  disabled?: AntDSelectProps['disabled'];
};

export function Select({
  defaultValue,
  value,
  searchValue,
  placeholder,
  showSearch,
  open,
  filterOption,
  onChange,
  onSearch,
  onBlur,
  onDropdownVisibleChange,
  onFocus,
  mode,
  options,
  style,
  dropdownRender,
  fieldNames,
  'data-testid': dataTestId,
  labelRender,
  disabled,
}: SelectProps) {
  return (
    <AntDSelect
      virtual={false}
      defaultValue={defaultValue}
      value={value}
      searchValue={searchValue}
      placeholder={placeholder}
      open={open}
      showSearch={showSearch}
      filterOption={filterOption}
      onChange={onChange}
      onSearch={onSearch}
      onBlur={onBlur}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onFocus={onFocus}
      options={options}
      mode={mode}
      style={style}
      data-testid={dataTestId}
      dropdownRender={dropdownRender}
      fieldNames={fieldNames}
      labelRender={labelRender}
      disabled={disabled}
    />
  );
}
