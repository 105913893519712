/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useState } from 'react';

import { ConfigRevision, ConfigRevisionFilters, ConfigRevisionsSortableFields, Node, NodeConfig } from '@/client';
import { PaginationParams } from '@/client/mgmtPlane';
import { Card } from '@/primitives/Card';
import { fromSorter } from '@/utils/sort';
import { ConfigRevisionsList, ConfigRevisionsListProps } from '@/v1/components/ConfigRevisionsList';
import { useConfigRevisionsQuery } from '@/v1/utils/hooks/useConfigRevisionsQuery';

export type SelectConfigRevisionCardProps = {
  node: Node;
  nodeConfig: NodeConfig;
  selectedConfigRevision?: ConfigRevision;
  onConfigRevisionSelect: (nodeConfig: ConfigRevision) => void;
};

export function SelectConfigRevisionCard({
  node,
  nodeConfig,
  selectedConfigRevision,
  onConfigRevisionSelect,
}: SelectConfigRevisionCardProps) {
  const [nodeConfigFilters, setConfigRevisionFilters] = useState<Partial<ConfigRevisionFilters>>({});
  const [nodeConfigSort, setConfigRevisionSort] = useState<ConfigRevisionsSortableFields>([]);
  const [nodeConfigPage, setConfigRevisionPage] = useState<PaginationParams>({ number: 1, size: 10 });
  const configsQuery = useConfigRevisionsQuery({
    tenantId: node.tenantId,
    configId: nodeConfig.id,
    filters: nodeConfigFilters,
    sort: nodeConfigSort,
    settings: nodeConfigPage,
  });

  const updateQueryParams: ConfigRevisionsListProps['onFilterAndSortChange'] = ({
    filters,
    sort,
    paginationParams,
  }) => {
    if (filters) setConfigRevisionFilters(filters);
    if (paginationParams) setConfigRevisionPage(paginationParams);
    if (sort) {
      const sortFields = fromSorter<ConfigRevisionsSortableFields, ConfigRevision>(sort);
      setConfigRevisionSort(sortFields);
    }
  };

  const getConfigRevisionPath = (configRevision: ConfigRevision) =>
    `/tenants/${node.tenantId}/node-configs/${configRevision.nodeConfigId}/revisions/${configRevision.revisionNumber}`;

  return (
    <Card dataTestId="config-wizard-select-node-config" padding="sm" bordered>
      <ConfigRevisionsList
        onFilterAndSortChange={updateQueryParams}
        configRevisionsQueryResponse={configsQuery}
        getConfigRevisionPath={getConfigRevisionPath}
        onConfigRevisionSelect={onConfigRevisionSelect}
        defaultConfigRevision={selectedConfigRevision}
      />
    </Card>
  );
}
