import { QueryClient, UseMutationOptions } from '@tanstack/react-query';

import { Node, OperationalStateChangeResp, RequestedOperationalState } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type UpdateNodeOperationalStateMutationConfig = {
  onError?: (error: unknown, variables: RequestedOperationalState) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

export function getUpdateNodeOperationalStateOptions(
  node: Node,
  tenantId: string,
  queryClient: QueryClient,
  config?: UpdateNodeOperationalStateMutationConfig,
): UseMutationOptions<OperationalStateChangeResp, unknown, RequestedOperationalState> {
  return {
    mutationFn: (updateOperationalState: RequestedOperationalState) => {
      return mgmtPlaneAPIClient.nodes.changeNodeOperationalState({
        tenantId,
        id: node.id,
        requestBody: updateOperationalState,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['node', tenantId, node.id] });
      queryClient.invalidateQueries({ queryKey: ['nodes'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  };
}
