/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Skeleton as AntSkeleton } from 'antd';

import { TShirtSize, tokens } from '@/theme';
import './Skeleton.css';

export type ThemeProps = {
  loading: boolean;
  children?: React.ReactNode;
  kind?: 'single' | 'paragraph';
  width?: TShirtSize;
  title?: boolean;
};

interface ParagraphProps {
  rows?: number;
}

interface TitleProps {
  width?: number;
}
export function Skeleton({ children, loading, kind, width, title }: ThemeProps) {
  const paragraphProps: ParagraphProps | boolean = {};
  let titleProps: TitleProps | boolean = false;

  switch (kind) {
    case 'paragraph':
      paragraphProps.rows = 3;
      break;
    case 'single':
    default:
      titleProps = false;
      paragraphProps.rows = 1;
      break;
  }

  if (title) {
    paragraphProps.rows = 0;

    titleProps = {
      width: tokens.size.skeleton[width || 'md'],
    };
  }
  return (
    <AntSkeleton loading={loading} paragraph={paragraphProps} active round title={titleProps}>
      {children}
    </AntSkeleton>
  );
}
