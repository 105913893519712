/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CloseOutlined } from '@ant-design/icons';

import { ConfigRevision } from '@/client';
import { Avatar } from '@/primitives/Avatar';
import { Button } from '@/primitives/Button';
import { Card } from '@/primitives/Card';
import { Col } from '@/primitives/Col';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { MimicSyntaxHighlighter } from '@/v1/components/MimicSyntaxHighlighter';
import { RelativeTime } from '@/v1/components/RelativeTime';

export type ConfigureNodesJobWizardSelectConfigRevisionCodeProps = {
  configRevision: ConfigRevision | undefined;
  setShowConfigCode: (showConfigCode: boolean) => void;
};

export function ConfigureNodesJobWizardSelectConfigRevisionCode({
  configRevision,
  setShowConfigCode,
}: ConfigureNodesJobWizardSelectConfigRevisionCodeProps) {
  if (!configRevision) {
    return null;
  }

  return (
    <Col span={12}>
      <Card padding="sm" style={{ width: '100%' }} gap="xs">
        <Flex align="center" justify="space-between" full>
          <Text type="default" size="md" mono>
            {configRevision.filename}
          </Text>
          <Button dataTestId="close-job-wizard" type="text" onClick={() => setShowConfigCode(false)}>
            <CloseOutlined />
          </Button>
        </Flex>
        <Text
          type="default"
          size="lg"
          strong
        >{`Revision #${configRevision.revisionNumber}: ${configRevision.description}`}</Text>
        <Flex align="center" gap="xs">
          <Avatar text={configRevision.createdBy.displayName[0]} size="xs" dataTestId="display-name" />
          <Text type="subtle" size="sm">
            {configRevision.createdBy.displayName},
          </Text>
          <Text type="subtle" size="sm">
            <RelativeTime date={configRevision!.createdAt} />
          </Text>
          <Text type="subtle" size="sm">
            |
          </Text>
          <Text type="subtle" size="sm" mono copyable>
            {configRevision.id}
          </Text>
        </Flex>
      </Card>
      <MimicSyntaxHighlighter source={configRevision.source} />
    </Col>
  );
}
