/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { MailOutlined } from '@ant-design/icons';
import { Form, FormInstance } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import { CreateUser } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Input } from '@/primitives/Input';
import { Select } from '@/primitives/Select';
import { Text } from '@/primitives/Text';
import { ValidationError } from '@/v1/components/Forms/ValidationError';

const requiredMarkAfter = (label: React.ReactNode) => label;

export type InviteMemberFormParams = {
  form: FormInstance<CreateUser>;
  selectedRole: string;
  setSelectedRole: Dispatch<SetStateAction<string>>;
};

export function InviteMemberForm({ form, selectedRole, setSelectedRole }: InviteMemberFormParams) {
  const { t } = useMimicTranslation('members');
  const { t: tGlobal } = useMimicTranslation('global');

  const handleChange = (value: string) => {
    setSelectedRole(value);
  };

  function roleMessage(roleValue: string) {
    if (roleValue === 'admin') {
      return t('roles.adminMessage');
    }
    if (roleValue === 'editor') {
      return t('roles.editorMessage');
    }
    if (roleValue === 'viewer') {
      return t('roles.viewerMessage');
    }
    return '';
  }

  return (
    <Form
      validateTrigger="onBlur"
      form={form}
      layout="vertical"
      requiredMark={requiredMarkAfter}
      initialValues={{ role: 'editor' }}
      data-testid="invite-member-form"
    >
      <Form.Item
        name="firstName"
        label={t('firstName')}
        rules={[
          { required: true, message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
          { type: 'string', message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
        ]}
      >
        <Input placeholder={t('invite.firstNamePlaceholder')} dataTestId="member-first-name" />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t('lastName')}
        rules={[
          { required: true, message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
          { type: 'string', message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
        ]}
      >
        <Input placeholder={t('invite.lastNamePlaceholder')} dataTestId="member-last-name" />
      </Form.Item>
      <Form.Item
        name="email"
        label={t('email')}
        rules={[
          { required: true, message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
          { min: 1, message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
          { type: 'email', message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
        ]}
      >
        <Input
          placeholder={t('invite.emailPlaceholder')}
          prefix={<MailOutlined style={{ marginRight: '8px' }} />}
          dataTestId="member-email"
        />
      </Form.Item>
      <Form.Item
        name="role"
        label={t('invite.permissions')}
        help={<Text size="md">{roleMessage(selectedRole)}</Text>}
        rules={[
          { required: true, message: <ValidationError>{t('invite.roleMissingError')}</ValidationError> },
          { type: 'string', message: <ValidationError>{t('invite.roleMissingError')}</ValidationError> },
        ]}
      >
        <Select
          placeholder={t('invite.permissionsPlaceholder')}
          data-testid="member-role"
          onChange={handleChange}
          defaultValue="editor"
          options={[
            { value: 'viewer', label: t('roles.viewer') },
            { value: 'editor', label: t('roles.editor') },
            { value: 'admin', label: t('roles.admin') },
          ]}
        />
      </Form.Item>
    </Form>
  );
}
