/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { BuildOutlined } from '@ant-design/icons';

import { ConfigRevision, NodeConfig } from '@/client';
import { Flex } from '@/primitives/Flex';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { tokens } from '@/theme';

import { MimicSyntaxHighlighter } from './MimicSyntaxHighlighter';

export type NodeConfigurationRevisionSourceCodeProps = {
  nodeConfiguration: NodeConfig;
  nodeConfigRevision: ConfigRevision;
  getNodeConfigurationPath: () => string;
};

export function NodeConfigurationRevisionSourceCode({
  nodeConfiguration,
  nodeConfigRevision,
  getNodeConfigurationPath,
}: NodeConfigurationRevisionSourceCodeProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Flex gap="xxs">
        <BuildOutlined />
        <Link
          to={getNodeConfigurationPath()}
          style={{
            fontSize: '1.1875rem',
            fontFamily: 'DM Mono',
            color: tokens.color.text.textSubtle,
          }}
          dataTestId="node-config-name"
        >
          {nodeConfiguration.name}
        </Link>
      </Flex>
      <Title
        style={{ fontWeight: 600, padding: '12px 0 24px 0' }}
        level={4}
      >{`Revision #${nodeConfigRevision.revisionNumber} · ${nodeConfigRevision.description}`}</Title>
      <Text type="subtle" style={{ fontWeight: 300, marginBottom: '12px' }} data-testid="revision-id" mono copyable>
        {nodeConfigRevision.id}
      </Text>
      <MimicSyntaxHighlighter source={nodeConfigRevision.source} />
    </div>
  );
}
