/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Tag as AntDTag } from 'antd';
import { ReactNode } from 'react';

import { tokens } from '@/theme';

export type TagProps = {
  children: ReactNode;
};

export function Tag({ children }: TagProps) {
  const borderColor = tokens.color.components.tag.tagLightGraySubtleBorder;
  const textColor = tokens.color.text.textDefault;
  const backgroundColor = tokens.color.components.tag.tagLightGraySubtleBg;

  return <AntDTag style={{ borderColor, color: textColor, backgroundColor, margin: 0 }}>{children}</AntDTag>;
}
