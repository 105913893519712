/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { BuildOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import dayjs from 'dayjs';

import { Node } from '@/client';
import { useMimicTranslation } from '@/hooks/useMimicTranslation';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { CardCollapse } from '@/v1/components/CardCollapse';
import { GuardedButton } from '@/v1/components/GuardedButton';
import { GuardedLink } from '@/v1/components/GuardedLink';
import { IncomingData } from '@/v1/components/Node/IncomingData';
import { NodeViewPageCard } from '@/v1/components/Node/NodeViewPageCard';

import { AssignConfig } from './AssignConfig';
import { Status } from './Status';

export type NodeConfigStateProps = {
  node: Node;
  assignNewNodeConfig: (node: Node) => void;
  assignNewConfigRevision: (node: Node) => void;
  getNodeConfigPath: (node: Node) => string;
  getNodeConfigRevisionPath: (node: Node) => string;
};

export function NodeConfigState({
  node,
  assignNewNodeConfig,
  assignNewConfigRevision,
  getNodeConfigPath,
  getNodeConfigRevisionPath,
}: NodeConfigStateProps) {
  const { t } = useMimicTranslation('node');

  const isPending = node.requestedRevisionState.status === 'pending';

  return (
    <CardCollapse>
      <NodeViewPageCard bodyStyles={{ paddingBottom: '12px', paddingTop: '16px' }}>
        <Form layout="vertical">
          <Flex vertical gap="sm">
            <Text type="default" style={{ fontWeight: 600, fontSize: '1.125rem' }}>
              {t('nodeConfiguration')}
            </Text>
            <Flex>
              <div style={{ marginRight: '48px' }}>
                <IncomingData
                  dataTestId="node-id"
                  label={t('node_id')}
                  loading={false}
                  text={node.id}
                  marginBottom={0}
                  mono
                  copyable
                />
              </div>
              <IncomingData label={t('node_version')} loading={false} text={node.nodeVersion} marginBottom={0} mono />
            </Flex>
          </Flex>
        </Form>
      </NodeViewPageCard>
      {node.isOperating || isPending ? (
        <NodeViewPageCard bodyStyles={{ paddingBottom: '0px', paddingTop: '16px' }}>
          <Flex vertical gap="xxs">
            <Text type="default" style={{ fontWeight: 600, fontSize: '1.125rem' }}>
              {t('currentConfiguration')}
            </Text>
            <Text type="subtle" size="md">
              {`${t('lastUpdatedBy')} ${node.appliedRevisionState?.assignedBy?.displayName}, ${dayjs(node.appliedRevisionState?.statusDate).utc().format('HH:mm:ss')} UTC`}
            </Text>
          </Flex>
          <Status
            node={node}
            getNodeConfigPath={getNodeConfigPath}
            getNodeConfigRevisionPath={getNodeConfigRevisionPath}
          />
          <div style={{ padding: '16px 0' }}>
            <Flex vertical>
              <Form layout="vertical">
                <Flex vertical>
                  <IncomingData label={t('configuration')} loading={isPending} marginBottom={0}>
                    <Flex justify="space-between" align="center">
                      <Flex gap="xs" vertical>
                        <GuardedLink
                          to={getNodeConfigPath(node)}
                          dataTestId="applied-configuration"
                          requiredRole="editor"
                        >
                          <BuildOutlined style={{ marginRight: '4px' }} />
                          {node.appliedRevisionState?.nodeConfig?.name}
                        </GuardedLink>
                        <Text type="subtle" mono copyable>
                          {node.appliedRevisionState?.nodeConfig?.id}
                        </Text>
                      </Flex>
                      <GuardedButton
                        requiredRole="superadmin"
                        type="default"
                        style={{ minWidth: '175px', minHeight: '36px' }}
                        onClick={() => assignNewNodeConfig(node)}
                      >
                        <Text type="default" size="md" strong>
                          {t('selectNewConfig')}
                        </Text>
                      </GuardedButton>
                    </Flex>
                  </IncomingData>
                  <IncomingData label={t('revision')} loading={isPending} marginBottom={0}>
                    <Flex justify="space-between" align="center">
                      <Flex gap="xs" vertical>
                        <GuardedLink
                          to={getNodeConfigRevisionPath(node)}
                          dataTestId="applied-revision"
                          requiredRole="superadmin"
                        >
                          {`#${node.appliedRevisionState?.configRevision?.revisionNumber} · ${node.appliedRevisionState?.configRevision?.description}`}
                        </GuardedLink>
                        <Text type="subtle" mono copyable>
                          {node.appliedRevisionState?.configRevision?.id}
                        </Text>
                      </Flex>
                      <GuardedButton
                        requiredRole="superadmin"
                        type="default"
                        style={{ minWidth: '175px', minHeight: '36px' }}
                        onClick={() => assignNewConfigRevision(node)}
                      >
                        <Text type="default" size="md" strong>
                          {t('selectNewRevision')}
                        </Text>
                      </GuardedButton>
                    </Flex>
                  </IncomingData>
                </Flex>
              </Form>
            </Flex>
          </div>
        </NodeViewPageCard>
      ) : (
        <AssignConfig assignNewNodeConfig={() => assignNewNodeConfig(node)} />
      )}
    </CardCollapse>
  );
}
