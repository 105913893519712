/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Flex as AntDFlex } from 'antd';
import { CSSProperties } from 'react';

import { TShirtSize, tokens } from '@/theme';

export type ContainerProps = {
  children?: React.ReactNode;
  padding?: TShirtSize;
  gap?: TShirtSize | 'none';
  horizontal?: boolean;
  justify?: React.CSSProperties['justifyContent'];
  full?: boolean;
  style?: CSSProperties;
};

export function Container({ gap, padding, horizontal, style, children, justify, full }: ContainerProps) {
  const gapSize = gap === 'none' ? 0 : tokens.size.space[gap || 'md'];
  const paddingPx = padding ? tokens.size.space[padding] : 0;
  const heightStyles: React.CSSProperties = {
    width: horizontal && full ? '100%' : undefined,
    height: !horizontal && full ? '100%' : undefined,
  };
  return (
    <AntDFlex
      vertical={!horizontal}
      gap={gapSize}
      justify={justify}
      align="flex-start"
      style={{
        padding: paddingPx,
        ...heightStyles,
        ...style,
      }}
    >
      {children}
    </AntDFlex>
  );
}

export type ContainerFixedProps = {
  children?: React.ReactNode;
};

Container.Fixed = function ContainerFixed({ children }: ContainerFixedProps) {
  return <div style={{ flexShrink: 1, flexGrow: 0, width: '100%' }}>{children}</div>;
};

export type ContainerExpandProps = {
  children?: React.ReactNode;
};

Container.Expand = function ContainerExpand({ children }: ContainerExpandProps) {
  return (
    <div
      style={{
        flexBasis: '0px',
        flexShrink: 0,
        flexGrow: 1,
        width: '100%',
        overflow: 'auto',
      }}
    >
      {children}
    </div>
  );
};
