/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { message as antdMessage } from 'antd';
import { ArgsProps, TypeOpen } from 'antd/es/message/interface';
import React, { ReactNode } from 'react';

import { EmptyFragment } from '@/primitives/EmptyFragment';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@/primitives/Icons';
import { WrapKey } from '@/primitives/WrapKey';
import { MessageInstance, useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import './message.css';

export type UseMessageOptions = {
  namespace?: string;
};

function changeIcon(openFn: TypeOpen, icon: ReactNode): TypeOpen {
  return (content, duration, onClose) => {
    if (React.isValidElement(content) || typeof content === 'string') {
      const d = typeof duration === 'number' ? duration : undefined;
      return openFn({
        content,
        duration: d,
        icon,
        className: 'toast-message',
      });
    }
    if (typeof content === 'object') {
      const args: ArgsProps = {
        icon,
        ...(content as ArgsProps),
      };
      return openFn(args, duration, onClose);
    }
    throw new Error('Invalid message arguments');
  };
}

export function useMessage(options?: UseMessageOptions): [MessageInstance, ReactNode] {
  const namespace = options?.namespace || 'default';

  const getMessageNamespace = useMgmtPlaneStateStore((state) => state.getMessageNamespace);
  const setMessageNamespace = useMgmtPlaneStateStore((state) => state.setMessageNamespace);

  if (!getMessageNamespace(namespace)) {
    const [antdAPI, context] = antdMessage.useMessage({ prefixCls: `${namespace}-message` });

    const api: MessageInstance = {
      feedback: changeIcon(antdAPI.info, EmptyFragment()),
      info: changeIcon(antdAPI.info, InfoIcon({ on: 'toast' })),
      warning: changeIcon(antdAPI.warning, WarningIcon({ on: 'toast' })),
      success: changeIcon(antdAPI.success, SuccessIcon({ on: 'toast' })),
      error: changeIcon(antdAPI.error, ErrorIcon({ on: 'toast' })),
    };

    setMessageNamespace(namespace, {
      api,
      // Ant gives all message contexts the same key so this wraps it with an unambiguous key
      context: WrapKey({ children: context, key: `${namespace}-wrapper` }),
    });
  }
  const instance = getMessageNamespace(namespace);
  if (!instance) {
    throw new Error(`Failed to build message namespace "${namespace}"`);
  }
  const { api, context } = instance;

  return [api, context];
}
